/* -------- Danh mục actions -------- */
import {
  API_CHINHSUAGOIHETHONG,
  API_CHITIETGOI,
  API_FAILED,
  API_FETCH,
  API_THEMMOIGOIHETHONG,
  API_XOAGOIHETHONG,
  GET_DATA_DS_GOIHETHONG
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelQuanLyGoiHeThongAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

export const getDanhSachGoiHeThong = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DS_GOIHETHONG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/system-package',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Chi tiết Gói
export const getChiTietGoi = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHITIETGOI, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/system-package/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Thêm mới Tài Khoản
export const postThemMoiGoiHeThong = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOIGOIHETHONG, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/system-package',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa Gói Hệ Thống
export const putChinhSuaGoiHeThong = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_CHINHSUAGOIHETHONG, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/system-package/${id}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Xóa gói Hệ Thống
export const deleteGoiHeThong = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_XOAGOIHETHONG, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/system-package/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
