/* -------- Reducer cho bảo mật dữ liệu -------- */
import {
  GET_DANHSACH_VAITRO
} from 'constants/actionTypes';

const initialState = {
  dsVaiTro: {}
};

export default function quanLyVaiTro(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DANHSACH_VAITRO:
      return {
        ...state,
        dsVaiTro: action.payload || {}
      };
    default:
      return state;
  }
}
