/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import {
  ChiTietTruyVan,
  DangNhapForm,
  DanhSachGoiHeThong,
  DanhSachTaiKhoan,
  DoiMatKhauForm,
  LandingPage,
  LayLaiMatKhauForm,
  LichSuTruyVan,
  NotFound,
  PhanBoChucNangVaoGoiHeThong,
  PhanQuyenTaiKhoan,
  TaoMoiMatKhauForm,
  ThemMoiChucDanh,
  ThemMoiChucNang,
  ThemMoiGoiHeThong,
  ThemMoiQuyen,
  ThemMoiTaiKhoan,
  TrangChu,
  UpdateThemMoiVaiTro,
  XemChiTietChucNang,
  XemChiTietGoi,
  XemChiTietQuyen,
  XemThongTinTaiKhoan,
  xemChiTietChucDanh,
  xemChiTietVaiTro
} from 'containers';
import DanhSachChucDanh from 'containers/quanLyChucDanh/DanhSachChucDanh';
import DanhSachChucNang from 'containers/quanLyChucNang/DanhSachChucNang';
import DanhSachQuyen from 'containers/quanLyQuyen/DanhSachQuyen';
import DanhSachVaiTro from 'containers/quanLyVaiTro/DanhSachVaiTro';
import PhanBoVaiTroVaoChucDanh from 'containers/quanLyChucDanh/PhanBoVaiTroVaoChucDanh';
import PhanBoQuyenVaoChucNang from 'containers/quanLyChucNang/PhanBoQuyenVaoChucNang';
import {
  LAYOUT_TYPE,
  LOGIN_ROUTE
} from 'constants/variables';
import AuthorizarionRoutes from 'routes/AuthorizarionRoutes';

const routes = [
  // Landing Page
  /* ------------------------------------------------------------------------- */
  { path: '/kho-du-lieu', exact: true, layout: null, isRequiredLogin: false, component: LandingPage },
  /* ------------------------------------------------------------------------- */
  { path: '/', exact: true, layout: null, isRequiredLogin: false, component: LandingPage },
  // Trang chủ
  /* ------------------------------------------------------------------------- */
  { path: '/trang-chu', exact: true, isRequiredLogin: true, component: TrangChu },
  /* ------------------------------------------------------------------------- */
  // Auth
  /* ------------------------------------------------------------------------- */
  { path: LOGIN_ROUTE, exact: true, layout: LAYOUT_TYPE.AUTH, isRequiredLogin: false, component: DangNhapForm },
  { path: '/lay-lai-mat-khau', exact: true, layout: LAYOUT_TYPE.AUTH, isRequiredLogin: false, component: LayLaiMatKhauForm },
  { path: '/tao-moi-mat-khau', exact: true, layout: LAYOUT_TYPE.AUTH, isRequiredLogin: false, component: TaoMoiMatKhauForm },
  { path: '/thong-tin-nguoi-dung/doi-mat-khau', exact: true, component: DoiMatKhauForm },
  /* ------------------------------------------------------------------------- */
  // Quản lý Người Dùng
  /* ------------------------------------------------------------------------- */
  { path: '/quan-ly-nguoi-dung/danh-sach-tai-khoan/:id/xem-chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.PHAN_QUYEN_GET(XemThongTinTaiKhoan) },
  { path: '/quan-ly-nguoi-dung/thong-tin-nguoi-dung', exact: true, component: AuthorizarionRoutes.PHAN_QUYEN_GET(XemThongTinTaiKhoan) },
  { path: '/quan-ly-nguoi-dung/chinh-sua-tai-khoan/:id', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.PHAN_QUYEN_PUT(ThemMoiTaiKhoan) },
  { path: '/quan-ly-nguoi-dung/danh-sach-tai-khoan', exact: true, component: AuthorizarionRoutes.PHAN_QUYEN_GET(DanhSachTaiKhoan) },
  { path: '/quan-ly-nguoi-dung/them-moi-tai-khoan', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.PHAN_QUYEN_POST(ThemMoiTaiKhoan) },
  { path: '/phan-quyen-tai-khoan/:id', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.PHAN_QUYEN_PUT(PhanQuyenTaiKhoan) },
  /* ------------------------------------------------------------------------- */
  // Quản lý Quyền
  /* ------------------------------------------------------------------------- */
  // Danh sách Quyền
  { path: '/quan-ly-quyen/danh-sach-quyen', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.QUYEN_GET(DanhSachQuyen) },
  // Thêm mới Quyền
  { path: '/quan-ly-quyen/them-moi-quyen', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.QUYEN_POST(ThemMoiQuyen) },
  // Chỉnh sửa Quyền
  { path: '/quan-ly-quyen/danh-sach-quyen/:id/chinh-sua', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.QUYEN_PUT(ThemMoiQuyen) },
  // Xem chi tiết Quyền
  { path: '/quan-ly-quyen/danh-sach-quyen/:id/xem-chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.QUYEN_GET(XemChiTietQuyen) },
  /* ------------------------------------------------------------------------- */
  // Quản lý Chức Năng
  /* ------------------------------------------------------------------------- */
  // Danh sách Chức Năng
  { path: '/quan-ly-chuc-nang/danh-sach-chuc-nang', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.FUNCTION_GET(DanhSachChucNang) },
  // Thêm mới Chức Năng
  { path: '/quan-ly-chuc-nang/them-moi-chuc-nang', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.FUNCTION_POST(ThemMoiChucNang) },
  // Chỉnh sửa Chức Năng
  { path: '/quan-ly-chuc-nang/chinh-sua-chuc-nang/:id/chinh-sua', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.FUNCTION_PUT(ThemMoiChucNang) },
  // Xem chi tiết Chức Năng
  { path: '/quan-ly-chuc-nang/danh-sach-chuc-nang/:id/xem-chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.FUNCTION_GET(XemChiTietChucNang) },
  // Phân Bổ  Quyền vào Chức Năng
  {
    path: '/quan-ly-chuc-nang/phan-bo-quyen-vao-chuc-nang',
    exact: true,
    isRequiredLogin: false,
    component: AuthorizarionRoutes.FUNCTION_POST(PhanBoQuyenVaoChucNang)
  },
  /* ------------------------------------------------------------------------- */
  // Quản lý chức danh
  /* ------------------------------------------------------------------------- */
  // Danh sách Chức Danh
  { path: '/quan-ly-chuc-danh/danh-sach-chuc-danh', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.TITLES_GET(DanhSachChucDanh) },
  // Phân bổ vai trò vào nhóm Chức Danh
  /* ------------------------------------------------------------------------- */
  // eslint-disable-next-line max-len
  { path: '/quan-ly-chuc-danh/phan-bo-vai-tro-chuc-danh', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.TITLES_POST(PhanBoVaiTroVaoChucDanh) },
  // Thêm mới chức danh
  { path: '/quan-ly-chuc-danh/them-moi-chuc-danh', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.TITLES_POST(ThemMoiChucDanh) },
  // Chỉnh sửa chức danh
  { path: '/quan-ly-chuc-danh/chinh-sua-chuc-danh/:id/chinh-sua', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.TITLES_PUT(ThemMoiChucDanh) },
  // Xem chi tiết Chức Danh
  { path: '/quan-ly-chuc-danh/danh-sach-chuc-danh/:id/xem-chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.TITLES_GET(xemChiTietChucDanh) },
  // Thêm mới vai trò
  { path: '/quan-ly-vai-tro/them-moi-vai-tro', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.ROLE_POST(UpdateThemMoiVaiTro) },
  // Thêm mới vai trò
  { path: '/quan-ly-vai-tro/:id/chinh-sua-vai-tro', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.ROLE_PUT(UpdateThemMoiVaiTro) },
  // Danh sách Vai Trò
  { path: '/quan-ly-vai-tro/danh-sach-vai-tro', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.ROLE_GET(DanhSachVaiTro) },
  // Xem chi tiết Vai Trò
  { path: '/quan-ly-vai-tro/danh-sach-vai-tro/:id/xem-chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.ROLE_GET(xemChiTietVaiTro) },
  /* ------------------------------------------------------------------------- */
  // Phân bổ quyền vào vai trò
  /* ------------------------------------------------------------------------- */
  // Quản lý gói hệ thống
  /* ------------------------------------------------------------------------- */
  { path: '/goi-he-thong/danh-sach', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.SYSTEM_PACKAGE_GET(DanhSachGoiHeThong) },
  // Chỉnh sửa Chức Năng
  { path: '/goi-he-thong/danh-sach/:id/chinh-sua', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.SYSTEM_PACKAGE_PUT(ThemMoiGoiHeThong) },
  // Xem chi tiết Gói Hệ Thống
  { path: '/goi-he-thong/danh-sach/:id/chi-tiet', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.SYSTEM_PACKAGE_GET(XemChiTietGoi) },
  // {
  //   path: '/goi-he-thong/phan-bo-nhom-quyen-vao-goi',
  //   exact: true,
  //   isRequiredLogin: false,
  //   component: PhanBoNhomQuyenVaoGoiHeThong
  // },
  {
    path: '/goi-he-thong/phan-bo-chuc-nang-vao-goi',
    exact: true,
    isRequiredLogin: false,
    component: AuthorizarionRoutes.SYSTEM_PACKAGE_PUT(PhanBoChucNangVaoGoiHeThong)
  },
  // Thêm mới Gói Hệ Thống
  { path: '/goi-he-thong/them-moi-goi-he-thong', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.SYSTEM_PACKAGE_POST(ThemMoiGoiHeThong) },
  /* ------------------------------------------------------------------------- */
  // Lịch sử truy vấn
  /* ------------------------------------------------------------------------- */
  { path: '/lich-su-truy-van', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.LOG_USER_ACTION_GET(LichSuTruyVan) },
  { path: '/chi-tiet-truy-van/:id', exact: true, isRequiredLogin: false, component: AuthorizarionRoutes.LOG_USER_ACTION_GET(ChiTietTruyVan) },
  /* ------------------------------------------------------------------------- */
  // Quản lý phiên bản
  /* ------------------------------------------------------------------------- */
  // { path: '/danh-sach-phien-ban', exact: true, isRequiredLogin: false, component: DanhSachPhienBan },
  /* ------------------------------------------------------------------------- */
  
  // Khác
  /* ------------------------------------------------------------------------- */
  // Not found
  { isRequiredLogin: false, layout: null, component: NotFound }
];

export default routes;
