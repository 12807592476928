
/* -------- Reducer cho danh mục -------- */
import {
  GET_DATA_DANHSACHQUYEN
} from 'constants/actionTypes';

const initialState = {
  dsQuyen: {}
};

export default function quyen(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_DANHSACHQUYEN:
      return {
        ...state,
        dsQuyen: action.payload || {}
      };
    default:
      return state;
  }
}
