/* eslint-disable max-len */
/* ------------------------------------------------------------------------- */
// Example
/* ------------------------------------------------------------------------- */
// Example 01
export const DQS_PERMISSION_EXAMPLE_MOT = 'DQS_PERMISSION_EXAMPLE_MOT';
// Danh sách example 01
export const DQS_PERMISSION_EXAMPLE_MOT_DS = 'DQS_PERMISSION_EXAMPLE_MOT_DS';
// Thêm mới example 01
export const DQS_PERMISSION_EXAMPLE_MOT_THEMMOI = 'DQS_PERMISSION_EXAMPLE_MOT_THEMMOI';
// Chỉnh sửa example 01
export const DQS_PERMISSION_EXAMPLE_MOT_CHINHSUA = 'DQS_PERMISSION_EXAMPLE_MOT_CHINHSUA';
// Xóa example 01
export const DQS_PERMISSION_EXAMPLE_MOT_XOA = 'DQS_PERMISSION_EXAMPLE_MOT_XOA';
// Danh sách lỗi chi tiết
export const DQS_DATA_GET = 'DQS_DATA_GET';
// Quản lý Tài Khoản
export const PHAN_QUYEN_GET = 'PHAN_QUYEN_GET';
// Thêm Tài Khoản
export const PHAN_QUYEN_POST = 'PHAN_QUYEN_POST';
// Sửa Tài Khoản
export const PHAN_QUYEN_PUT = 'PHAN_QUYEN_PUT';
// Xóa Tài Khoản
export const PHAN_QUYEN_DELETE = 'PHAN_QUYEN_DELETE';
// Danh sách Chức Năng
export const FUNCTION_GET = 'FUNCTION_GET';
// Thêm Chức Năng
export const FUNCTION_POST = 'FUNCTION_POST';
// Sửa Chức Năng
export const FUNCTION_PUT = 'FUNCTION_PUT';
// Xóa Chức Năng
export const FUNCTION_DELETE = 'FUNCTION_DELETE';
// Danh sách lịch sử truy vấn
export const LOG_USER_ACTION_GET = 'LOG_USER_ACTION_GET';
// Thêm lịch sử truy vấn
export const LOG_USER_ACTION_POST = 'LOG_USER_ACTION_POST';
// Sửa lịch sử truy vấn
export const LOG_USER_ACTION_PUT = 'LOG_USER_ACTION_PUT';
// Xóa lịch sử truy vấn
export const LOG_USER_ACTION_DELETE = 'LOG_USER_ACTION_DELETE';
// Danh sách Quyền
export const QUYEN_GET = 'QUYEN_GET';
// Thêm Quyền
export const QUYEN_POST = 'QUYEN_POST';
// Sửa Quyền
export const QUYEN_PUT = 'QUYEN_PUT';
// Xóa Quyền
export const QUYEN_DELETE = 'QUYEN_DELETE';
// Danh sách Vai Trò
export const ROLE_GET = 'ROLE_GET';
// Thêm Vai Trò
export const ROLE_POST = 'ROLE_POST';
// Sửa Vai Trò
export const ROLE_PUT = 'ROLE_PUT';
// Xóa Vai Trò
export const ROLE_DELETE = 'ROLE_DELETE';
// Danh sách Gói Hệ Thống
export const SYSTEM_PACKAGE_GET = 'SYSTEM_PACKAGE_GET';
// Thêm Gói Hệ Thống
export const SYSTEM_PACKAGE_POST = 'SYSTEM_PACKAGE_POST';
// Sửa Gói Hệ Thống
export const SYSTEM_PACKAGE_PUT = 'SYSTEM_PACKAGE_PUT';
// Xóa Gói Hệ Thống
export const SYSTEM_PACKAGE_DELETE = 'SYSTEM_PACKAGE_DELETE';
// Danh sách Chức Danh
export const TITLES_GET = 'TITLES_GET';
// Thêm Chức Danh
export const TITLES_POST = 'TITLES_POST';
// Sửa Chức Danh
export const TITLES_PUT = 'TITLES_PUT';
// Xóa Chức Danh
export const TITLES_DELETE = 'TITLES_DELETE';
// Phân bổ người dùng vào nhóm chức danh
export const PHAN_BO_NGUOI_DUNG_PUT = 'PHAN_BO_NGUOI_DUNG_PUT';
// Quyền All Hệ Thống
export const QUYEN_GOD = 'QUYEN_GOD';
