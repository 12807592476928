/* eslint-disable no-const-assign */
/* -------- Nang mục actions -------- */
import {
  API_CHINHSUACHUCNANG,
  API_CHITIETCHUCNANG,
  API_CHUCNANG_IDHETHONG,
  API_DELETECHUCNANG,
  API_FAILED,
  API_FETCH,
  API_PHANBOQUYENCHUCNANG,
  API_THEMMOICHUCNANG,
  GET_DATA_DANHSACHCHUCNANG
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelDangSachChucNangAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách Chức Năng
export const getAllDanhSachChucNang = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  const getUrl = '/api/function';
  return dispatch({
    types: [API_FETCH, GET_DATA_DANHSACHCHUCNANG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: getUrl,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params,
          permission_id: params && params.permission_id ? params.permission_id : null
        }
      }
    }
  });
};

// Chi tiết Chức Năng
export const getChiTietChucNang = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHITIETCHUCNANG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/function/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Thêm mới Chức Năng
export const postThemMoiChucNang = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOICHUCNANG, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/function',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa Chức Năng
export const putChinhSuaChucNang = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_CHINHSUACHUCNANG, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/function/${id}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Xóa Chức Năng
export const deleteChucNang = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DELETECHUCNANG, API_FAILED],
  payload: {
    request: {
      url: `/api/function/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Get chức năng theo ID Gói Hệ Thống
export const getChucNangTheoIDHeThong = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHUCNANG_IDHETHONG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/function',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Phân bổ chức năng vào gói hệ thống
export const postPhanBoChucNang = (id, data) => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_THEMMOICHUCNANG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/system-package/${id}/phan-bo-chuc-nang`,
        method: 'PUT',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        data: data.listChucNang
      }
    }
  });
};

// Phân bổ quyền và chức năng
export const putPhanBoQuyenVaoChucNang = (id, data) => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_PHANBOQUYENCHUCNANG, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/function/${id}`,
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        data: data.listQuyen
      }
    }
  });
};
