/* -------- Chức danh actions -------- */
import {
  API_CHITIETCHUCDANH,
  API_DEFAULT,
  API_FAILED,
  API_FETCH,
  API_THEMMOICHUCDANH,
  API_XOACHUCDANH,
  GET_DATA_DANHSACHCHUCDANH
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelQuanLyChucDanhAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách Chức Danh
export const getAllDanhSachChucDanh = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DANHSACHCHUCDANH, API_FAILED],
    payload: {
      request: {
        url: '/api/titles',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Thêm mới Chức Danh
export const postThemMoiChucDanh = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOICHUCDANH, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/titles',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa Chức Danh
export const putChinhSuaChucDanh = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOICHUCDANH, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/titles/${id}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chi tiết Chức Danh
export const getChiTietChucDanh = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHITIETCHUCDANH, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/titles/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Xóa Quyền
export const deleteChucDanh = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_XOACHUCDANH, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/titles/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// PUT phân bổ vai trò vào nhóm chức danh
export const postPhanBoVaiTroVaoNhomChucDanh = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DEFAULT, API_FAILED],
  payload: {
    request: {
      url: `/api/titles/${data.id}`,
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
