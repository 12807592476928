/* -------- Danh mục actions -------- */
import {
  API_FAILED,
  API_FETCH,
  GET_DATA_COUNT_COLUMN,
  GET_DATA_COUNT_NDS,
  GET_DATA_COUNT_RULES,
  GET_DATA_COUNT_STANDARDS,
  GET_DATA_COUNT_STORE,
  GET_DATA_COUNT_TABLE,
  GET_LAST_UPDATE
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelThongKeAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách tất cả example 01
export const getCountStandard = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_STANDARDS, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: '/api/dq-standards/count',
        method: 'GET',
        cancelToken,
        params: {
          ...params,
          size: 0
        }
      }
    }
  });
};

// Danh sách example 01
export const getCountRule = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_RULES, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: '/api/dq-rules/count',
        method: 'GET',
        cancelToken,
        params
      }
    }
  });
};

export const getCountStore = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_STORE, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: 'api/ds-stores/count',
        method: 'GET',
        cancelToken,
        params: {
          'parentSystemStoreId.equals': 3
        }
      }
    }
  });
};

export const getCountTable = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_TABLE, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: 'api/ds-tables/count',
        method: 'GET',
        cancelToken,
        params: {
          'tblTypeId.equals': 1
        }
      }
    }
  });
};

export const getCountColumn = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_COLUMN, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: 'api/ds-columns/count',
        method: 'GET',
        cancelToken,
        params: {
          'tblTypeId.equals': 1
        }
      }
    }
  });
};

export const getCountTotalNDS = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_COUNT_NDS, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: 'api/table-statistics',
        method: 'GET',
        cancelToken,
        params: {
          size: 2147483647
        }
      }
    }
  });
};

export const getThoiGianChuanHoa = type => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_LAST_UPDATE, API_FAILED],
    payload: {
      client: 'datagov',
      request: {
        url: 'api/table-statistics/last-update',
        method: 'GET',
        cancelToken,
        params: {
          criteria: type
        }
      }
    }
  });
};
