import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Cookies } from 'react-cookie';
import { AuthLayout, MasterLayout } from 'containers';
import {
  COOKIES_NAME,
  COOKIES_OPTION,
  LAYOUT_TYPE,
  LOGIN_ROUTE,
  ROUTE_NO_LOGIN_LIST
} from 'constants/variables';
import { ConnectedSwitch } from 'routes';

const cookies = new Cookies();

const renderComponent = (route, props) => {
  if (isEmpty(route)) {
    return null;
  }
  const { layout = LAYOUT_TYPE.MASTER } = route;
  switch (layout) {
    // hiển thị component được bọc bởi MasterLayout
    case LAYOUT_TYPE.MASTER:
      return (
        <MasterLayout {...props}>
          <route.component
            {...props}
            routes={route.routes}
          />
        </MasterLayout>
      );
      // Hiển thị component được bọc bởi AuthLayout
    case LAYOUT_TYPE.AUTH:
      return (
        <AuthLayout {...props}>
          <route.component
            {...props}
            routes={route.routes}
          />
        </AuthLayout>
      );
    default:
      return (
        <route.component
          {...props}
          routes={route.routes}
        />
      );
  }
};

const RenderRoutes = ({ routes, auth, location }) => {
  if (!routes) {
    return null;
  }
  const authInfo = cookies.get(COOKIES_NAME.AUTH, COOKIES_OPTION);
  const path = get(location, 'pathname');
  const accessToken = get(auth, 'token.accessToken') || get(authInfo, 'token.accessToken');
  const queryParams = QueryString.parse(get(location, 'search'));
  return (
    <ConnectedSwitch>
      { routes.map((route, i) => (
        <Route
          key={i}
          exact
          path={route.path}
          render={props => (
            <React.Fragment>
              {
                !ROUTE_NO_LOGIN_LIST.includes(path) && route.isRequiredLogin !== false && (
                  !accessToken
                ) && (
                  <Redirect
                    to={{
                      pathname: LOGIN_ROUTE
                    }}
                  />
                )
              }
              {
                (
                  route.isRequiredLogin === false
                  || accessToken
                  || ROUTE_NO_LOGIN_LIST.includes(path)
                ) && renderComponent(route, { ...props, queryParams })
              }
            </React.Fragment>
          )}
        />
      ))}
    </ConnectedSwitch>
  );
};

RenderRoutes.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, null)(RenderRoutes));
