
/* -------- Reducer cho danh mục -------- */
import {
  GET_DATA_COUNT_COLUMN,
  GET_DATA_COUNT_NDS,
  GET_DATA_COUNT_RULES,
  GET_DATA_COUNT_STANDARDS,
  GET_DATA_COUNT_STORE,
  GET_DATA_COUNT_TABLE
} from 'constants/actionTypes';
import { get, isEmpty } from 'lodash';

const initialState = {
  countRule: 0,
  countStandard: 0,
  countStore: 0,
  countTable: 0,
  countColumn: 0,
  countNDS: 0
};

export default function thongKe(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_COUNT_RULES:
      return {
        ...state,
        countRule: action.payload
      };
    case GET_DATA_COUNT_STANDARDS:
      return {
        ...state,
        countStandard: action.payload
      };
    case GET_DATA_COUNT_STORE:
      return {
        ...state,
        countStore: action.payload
      };
    case GET_DATA_COUNT_TABLE:
      return {
        ...state,
        countTable: action.payload
      };
    case GET_DATA_COUNT_COLUMN:
      return {
        ...state,
        countColumn: action.payload
      };
    case GET_DATA_COUNT_NDS:
      // eslint-disable-next-line no-case-declarations
      let countNDS = 0;
      // eslint-disable-next-line no-case-declarations
      const listData = get(action, 'payload.content');
      if (!isEmpty(listData)) {
        listData.forEach((item) => {
          if (item.recordNDS.numberOriginalRecord) {
            countNDS += item.recordNDS.numberOriginalRecord;
          }
        });
      }
      return {
        ...state,
        countNDS
      };
    default:
      return state;
  }
}
