/* -------- Reducer chung-------- */
import {
  GET_DANHSACH_STORE,
  GET_DATA_DANHSACHCHUCNANG,
  GET_DATA_DS_GOIHETHONG,
  GET_DATA_QUAN_HUYEN,
  SET_LOADING_APP,
  SET_SHOULD_LOAD_INITIAL_COMBOBOXS
} from 'constants/actionTypes';
import { convertResponseAPI } from 'helpers';

const initialState = {
  loadingApp: false,
  shouldLoadInitialComboboxs: false,
  dataQuanHuyen: [],
  dsStore: []
};

export default function common(state = initialState, action = {}) {
  switch (action.type) {
    // Set loading cho App (chẳng hạn khi get thông tin user)
    case SET_LOADING_APP:
      return {
        ...state,
        loadingApp: action.loading
      };
    // Set điều kiện load comboboxs khởi tạo
    case SET_SHOULD_LOAD_INITIAL_COMBOBOXS:
      return {
        ...state,
        shouldLoadInitialComboboxs: action.value
      };
    case GET_DATA_QUAN_HUYEN:
      return {
        ...state,
        dataQuanHuyen: convertResponseAPI(action).list
      };
    case GET_DATA_DS_GOIHETHONG:
      return {
        ...state,
        dsGoiHeThong: action.payload || {}
      };
    case GET_DATA_DANHSACHCHUCNANG:
      return {
        ...state,
        dsChucNang: action.payload || {}
      };
    case GET_DANHSACH_STORE:
      return {
        ...state,
        dsStore: action.payload || []
      };
    default:
      return state;
  }
}
