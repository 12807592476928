import React from 'react';
import loadable from '@loadable/component';
import { Loading } from 'components/common';

/* ------------------------------------------------------------------------- */
// Common
/* ------------------------------------------------------------------------- */
const AuthLayout = loadable(() => import('./AuthLayout'), {
  fallback: <Loading />
});
const MasterLayout = loadable(() => import('./MasterLayout'), {
  fallback: <Loading />
});
const NotFound = loadable(() => import('./NotFound'), {
  fallback: <Loading />
});
const PrepareFeature = loadable(() => import('./PrepareFeature'), {
  fallback: <Loading />
});

/* ------------------------------------------------------------------------- */
// Trang chủ
/* ------------------------------------------------------------------------- */
const TrangChu = loadable(() => import('./trangChu/TrangChu'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Auth
/* ------------------------------------------------------------------------- */
const LayLaiMatKhauForm = loadable(() => import('./auth/LayLaiMatKhauForm'), {
  fallback: <Loading />
});
const TaoMoiMatKhauForm = loadable(() => import('./auth/TaoMoiMatKhauForm'), {
  fallback: <Loading />
});
const DangNhapForm = loadable(() => import('./auth/DangNhapForm'), {
  fallback: <Loading />
});
// const ThongTinNguoiDungForm = loadable(() => import('./auth/ThongTinNguoiDungForm'), {
//   fallback: <Loading />
// });
const DoiMatKhauForm = loadable(() => import('./auth/DoiMatKhauForm'), {
  fallback: <Loading />
});
// Quản lý Tài Khoản
/* ------------------------------------------------------------------------- */
const DanhSachTaiKhoan = loadable(() => import('./quanLyNguoiDung/DanhSachTaiKhoan'), {
  fallback: <Loading />
});
const PhanQuyenTaiKhoan = loadable(() => import('./quanLyNguoiDung/PhanQuyenTaiKhoan'), {
  fallback: <Loading />
});
const XemThongTinTaiKhoan = loadable(() => import('./quanLyNguoiDung/XemThongTinTaiKhoan'), {
  fallback: <Loading />
});
const ThemMoiTaiKhoan = loadable(() => import('./quanLyNguoiDung/ThemMoiTaiKhoan'), {
  fallback: <Loading />
});
// Danh sách Quyền
/* ------------------------------------------------------------------------- */
const DanhSachQuyen = loadable(() => import('./quanLyQuyen/DanhSachQuyen'), {
  fallback: <Loading />
});
// Phân bổ Chức Năng vào Quyền
/* ------------------------------------------------------------------------- */
const PhanBoChucNangVaoQuyen = loadable(() => import('./quanLyQuyen/PhanBoChucNangVaoQuyen'), {
  fallback: <Loading />
});
// Thêm mới Quyền
/* ------------------------------------------------------------------------- */
const ThemMoiQuyen = loadable(() => import('./quanLyQuyen/ThemMoiQuyen'), {
  fallback: <Loading />
});
// Xem chi tiết Quyền
/* ------------------------------------------------------------------------- */
const XemChiTietQuyen = loadable(() => import('./quanLyQuyen/XemChiTietQuyen'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Thêm mới Chức Danh
/* ------------------------------------------------------------------------- */
const ThemMoiChucDanh = loadable(() => import('./quanLyChucDanh/ThemMoiChucDanh'), {
  fallback: <Loading />
});
// Thêm mới Gói Hệ Thống
/* ------------------------------------------------------------------------- */
const ThemMoiGoiHeThong = loadable(() => import('./quanlygoihethong/ThemMoiGoiHeThong'), {
  fallback: <Loading />
});
// Xem chi tiết Gói Hệ Thống
/* ------------------------------------------------------------------------- */
const XemChiTietGoi = loadable(() => import('./quanlygoihethong/XemChiTietGoi'), {
  fallback: <Loading />
});
// Danh sách Chức Năng
/* ------------------------------------------------------------------------- */
const DanhSachChucNang = loadable(() => import('./quanLyChucNang/DanhSachChucNang'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Thêm mới Chức Năng
/* ------------------------------------------------------------------------- */
const ThemMoiChucNang = loadable(() => import('./quanLyChucNang/ThemMoiChucNang'), {
  fallback: <Loading />
});
// Xem chi tiết Chức Năng
/* ------------------------------------------------------------------------- */
const XemChiTietChucNang = loadable(() => import('./quanLyChucNang/XemChiTietChucNang'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Danh sách Chức Danh
/* ------------------------------------------------------------------------- */
const DanhSachChucDanh = loadable(() => import('./quanLyChucDanh/DanhSachChucDanh'), {
  fallback: <Loading />
});
// Xem chi tiết Chức Danh
/* ------------------------------------------------------------------------- */
const xemChiTietChucDanh = loadable(() => import('./quanLyChucDanh/xemChiTietChucDanh'), {
  fallback: <Loading />
});
// Danh sách Chức Danh
/* ------------------------------------------------------------------------- */
const PhanBoNguoiDungVaoChucDanh = loadable(() => import('./quanLyChucDanh/PhanBoNguoiDungVaoChucDanh'), {
  fallback: <Loading />
});
// Danh sách Chức Danh
/* ------------------------------------------------------------------------- */
const PhanBoVaiTroVaoChucDanh = loadable(() => import('./quanLyChucDanh/PhanBoVaiTroVaoChucDanh'), {
  fallback: <Loading />
});
// Danh sách Vai Trò
/* ------------------------------------------------------------------------- */
const DanhSachVaiTro = loadable(() => import('./quanLyVaiTro/DanhSachVaiTro'), {
  fallback: <Loading />
});
// Thêm mới Vai Trò
/* ------------------------------------------------------------------------- */
const UpdateThemMoiVaiTro = loadable(() => import('./quanLyVaiTro/UpdateThemMoiVaiTro'), {
  fallback: <Loading />
});
// Xem chi tiết Vai Trò
/* ------------------------------------------------------------------------- */
const xemChiTietVaiTro = loadable(() => import('./quanLyVaiTro/xemChiTietVaiTro'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Quản lý gói hệ thống
/* ------------------------------------------------------------------------- */
const DanhSachGoiHeThong = loadable(() => import('./quanlygoihethong/DanhSachGoiHeThong'), {
  fallback: <Loading />
});
const PhanBoNhomQuyenVaoGoiHeThong = loadable(() => import('./quanlygoihethong/PhanBoNhomQuyenVaoGoiHeThong'), {
  fallback: <Loading />
});
const PhanBoChucNangVaoGoiHeThong = loadable(() => import('./quanlygoihethong/PhanBoChucNangVaoGoiHeThong'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Quản lý lịch sử
/* ------------------------------------------------------------------------- */
const LichSuTruyVan = loadable(() => import('./lichSuTruyVan/LichSuTruyVan'), {
  fallback: <Loading />
});
const ChiTietTruyVan = loadable(() => import('./lichSuTruyVan/ChiTietTruyVan'), {
  fallback: <Loading />
});
/* ------------------------------------------------------------------------- */
// Quản lý phiên bản
/* ------------------------------------------------------------------------- */
const DanhSachPhienBan = loadable(() => import('./quanLyPhienBan/DanhSachPhienBan'), {
  fallback: <Loading />
});

/* ------------------------------------------------------------------------- */
// LandingPage
/* ------------------------------------------------------------------------- */
const LandingPage = loadable(() => import('./landingPage/LandingPage'), {
  fallback: <Loading />
});

export {
  ChiTietTruyVan,
  LichSuTruyVan,
  MasterLayout,
  AuthLayout,
  DangNhapForm,
  DoiMatKhauForm,
  LayLaiMatKhauForm,
  TaoMoiMatKhauForm,
  NotFound,
  PrepareFeature,
  PhanBoNhomQuyenVaoGoiHeThong,
  PhanBoChucNangVaoGoiHeThong,
  PhanBoChucNangVaoQuyen,
  PhanQuyenTaiKhoan,
  PhanBoNguoiDungVaoChucDanh,
  PhanBoVaiTroVaoChucDanh,
  TrangChu,
  ThemMoiChucDanh,
  ThemMoiChucNang,
  ThemMoiTaiKhoan,
  ThemMoiQuyen,
  ThemMoiGoiHeThong,
  DanhSachChucDanh,
  DanhSachTaiKhoan,
  DanhSachChucNang,
  DanhSachQuyen,
  xemChiTietChucDanh,
  XemChiTietChucNang,
  UpdateThemMoiVaiTro,
  xemChiTietVaiTro,
  XemChiTietGoi,
  XemThongTinTaiKhoan,
  XemChiTietQuyen,
  DanhSachVaiTro,
  DanhSachGoiHeThong,
  DanhSachPhienBan,
  LandingPage
};
