/* -------- Danh mục actions -------- */
import {
  API_CHUCDANHID,
  API_DEFAULT,
  API_FAILED,
  API_FETCH,
  API_POSTDANHSACHQUYEN,
  API_VAITROID,
  GET_DANH_SACH_DAC_QUYEN,
  GET_DANH_SACH_TAI_KHOAN
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelBaoMatDuLieuAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách tài khoản
export const getDanhSachTaiKhoan = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DANH_SACH_TAI_KHOAN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/phan-quyen/account',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params
      }
    }
  });
};

// Get dữ liệu quyền/chức danh/vai trò của 1 user
export const getDataChucDanhVaiTroVaQuyen = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/821d2340-b132-11ea-93a7-296a0c8a9e81',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params
      }
    }
  });
};

// Get chức danh theo ID User
export const getChucDanhTheoID = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHUCDANHID, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/phan-bo-nguoi-dung/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Get vai trò theo ID User
export const getVaiTroTheoID = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_VAITROID, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/phan-quyen/vai-tro/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Danh sách đặc quyền
export const getDanhSachDacQuyen = () => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DANH_SACH_DAC_QUYEN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/dac-quyen',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Lưu danh sách đặc qiuyền
export const postDanhSachPhanQuyen = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_POSTDANHSACHQUYEN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/phan-quyen',
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
