import PropTypes from 'prop-types';
import {
  get,
  isFunction
} from 'lodash';
import {
  AUTH_DOMAIN,
  AUTH_LOGIN_PATH,
  REDIRECT_CALLBACK_DOMAIN
} from 'constants/api';
import {
  QUERY_PARAM,
  WINDOW_OPEN_TOKEN_OPTION
} from 'constants/variables';
import {
  redirectTo
} from 'helpers';

const RedirectExternal = (props) => {
  // Lấy pathname đang đứng
  const pathname = get(props, 'location.pathname');
  // Lấy hasLogout từ location state và kiểm tra user có vừa logout hay không
  const hasLogout = get(props, 'location.state.hasLogout');
  // Tạo query params
  const queryParams = {};
  if (!hasLogout) {
    queryParams[QUERY_PARAM.REDIRECT_CALLBACK] = `${REDIRECT_CALLBACK_DOMAIN}${pathname}`;
  }
  // Reset hasLogout từ location state
  const replace = get(props, 'history.replace');
  if (isFunction(replace)) {
    props.history.replace({
      state: undefined
    });
  }
  // Tạo redirect path (Auth)
  const redirectPath = `${AUTH_DOMAIN}${AUTH_LOGIN_PATH}`;
  // Điều hướng tới hệ thống Auth để login
  const resultRedirect = redirectTo(redirectPath, queryParams, WINDOW_OPEN_TOKEN_OPTION.EXTERNAL_AUTH_LOGIN);
  // Nếu không thể điều hướng tới hệ thống quản trị người dùng
  if (!resultRedirect) {
    const push = get(props, 'history.push');
    if (isFunction(push)) {
      // Điều hướng tới trang không tìm thấy (route không được định nghĩa)
      props.history.push('/dang-nhap');
    }
  }
  // Return render
  return null;
};

RedirectExternal.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

export default RedirectExternal;
