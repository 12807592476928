/* -------- Định nghĩa wrapper component (Quyền truy cập page, quyền thực hiện action) -------- */
import Authorization from 'components/auth/Authorization';
import * as Permission from 'components/auth/Permission';

const AuthorizarionRoutes = {
  /* ------------------------------------------------------------------------- */
  // TODO: Example - Trường hợp điều kiện hoặc (Xóa đi khi thực hiện chức năng thực)
  /* ------------------------------------------------------------------------- */
  EXAMPLE_OR_CONDITION: Authorization.Component([], true, [], {
    conditions: [
      // Nếu có quyền DQS_PERMISSION_EXAMPLE_MOT_THEMMOI
      {
        permission: [
          Permission.DQS_PERMISSION_EXAMPLE_MOT_THEMMOI
        ]
      },
      // Hoặc có quyền DQS_PERMISSION_EXAMPLE_MOT_CHINHSUA
      {
        permission: [
          Permission.DQS_PERMISSION_EXAMPLE_MOT_CHINHSUA
        ]
      }
    ],
    isOrConditions: true
  }),
  /* ------------------------------------------------------------------------- */
  // Quản lý Tài Khoản
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Tài Khoản
  PHAN_QUYEN_GET: Authorization.Component([
    Permission.PHAN_QUYEN_GET
  ], true),

  // Quyền thêm Tài Khoản
  PHAN_QUYEN_POST: Authorization.Component([
    Permission.PHAN_QUYEN_POST
  ], true),

  // Quyền sửa Tài Khoản
  PHAN_QUYEN_PUT: Authorization.Component([
    Permission.PHAN_QUYEN_PUT
  ], true),

  // Quyền xóa Tài Khoản
  PHAN_QUYEN_DELETE: Authorization.Component([
    Permission.PHAN_QUYEN_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Chức Năng
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Chức Năng
  FUNCTION_GET: Authorization.Component([
    Permission.FUNCTION_GET
  ], true),

  // Quyền thêm Chức Năng
  FUNCTION_POST: Authorization.Component([
    Permission.FUNCTION_POST
  ], true),

  // Quyền sửa Chức Năng
  FUNCTION_PUT: Authorization.Component([
    Permission.FUNCTION_PUT
  ], true),

  // Quyền xóa Chức Năng
  FUNCTION_DELETE: Authorization.Component([
    Permission.FUNCTION_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Lịch sử truy vấn
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Lịch sử truy vấn
  LOG_USER_ACTION_GET: Authorization.Component([
    Permission.LOG_USER_ACTION_GET
  ], true),
  // Quyền thêm Lịch sử truy vấn
  LOG_USER_ACTION_POST: Authorization.Component([
    Permission.LOG_USER_ACTION_POST
  ], true),
  // Quyền sửa Lịch sử truy vấn
  LOG_USER_ACTION_PUT: Authorization.Component([
    Permission.LOG_USER_ACTION_PUT
  ], true),
  // Quyền xóa Lịch sử truy vấn
  LOG_USER_ACTION_DELETE: Authorization.Component([
    Permission.LOG_USER_ACTION_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Quyền
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Quyền
  QUYEN_GET: Authorization.Component([
    Permission.QUYEN_GET
  ], true),

  // Quyền thêm Quyền
  QUYEN_POST: Authorization.Component([
    Permission.QUYEN_POST
  ], true),

  // Quyền sửa Quyền
  QUYEN_PUT: Authorization.Component([
    Permission.QUYEN_PUT
  ], true),

  // Quyền xóa Quyền
  QUYEN_DELETE: Authorization.Component([
    Permission.QUYEN_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Vai Trò
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Vai trò
  ROLE_GET: Authorization.Component([
    Permission.ROLE_GET
  ], true),

  // Quyền thêm Vai trò
  ROLE_POST: Authorization.Component([
    Permission.ROLE_POST
  ], true),

  // Quyền sửa Vai trò
  ROLE_PUT: Authorization.Component([
    Permission.ROLE_PUT
  ], true),

  // Quyền xóa Vai trò
  ROLE_DELETE: Authorization.Component([
    Permission.ROLE_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Gói hệ thống
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Gói hệ thống
  SYSTEM_PACKAGE_GET: Authorization.Component([
    Permission.SYSTEM_PACKAGE_GET
  ], true),

  // Quyền thêm Gói hệ thống
  SYSTEM_PACKAGE_POST: Authorization.Component([
    Permission.SYSTEM_PACKAGE_POST
  ], true),

  // Quyền sửa Gói hệ thống
  SYSTEM_PACKAGE_PUT: Authorization.Component([
    Permission.SYSTEM_PACKAGE_PUT
  ], true),

  // Quyền xóa Gói hệ thống
  SYSTEM_PACKAGE_DELETE: Authorization.Component([
    Permission.SYSTEM_PACKAGE_DELETE
  ], true),
  /* ------------------------------------------------------------------------- */
  // Quản lý Chức danh
  /* ------------------------------------------------------------------------- */
  // Quyền truy cập danh sách Chức Danh
  TITLES_GET: Authorization.Component([
    Permission.TITLES_GET
  ], true),

  // Quyền thêm Chức Danh
  TITLES_POST: Authorization.Component([
    Permission.TITLES_POST
  ], true),

  // Quyền sửa Chức Danh
  TITLES_PUT: Authorization.Component([
    Permission.TITLES_PUT
  ], true),
  
  // Quyền xóa Chức Danh
  TITLES_DELETE: Authorization.Component([
    Permission.TITLES_DELETE
  ], true),

  // Phân bổ người dùng vào nhóm chức danh
  PHAN_BO_NGUOI_DUNG_PUT: Authorization.Component([
    Permission.PHAN_BO_NGUOI_DUNG_PUT
  ], true)
};

export default AuthorizarionRoutes;
