/* -------- Auth actions -------- */
import axios from 'axios';
import { get } from 'lodash';
import {
  API_DEFAULT,
  API_FAILED,
  API_FETCH,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
  GET_INFO_USER,
  SWITCH_ROLE
} from 'constants/actionTypes';
import { generateKeyAPI, toNumber } from 'helpers';
import { QUERY_PARAM } from 'constants/variables';
import {
  CLIENT_ID,
  CLIENT_PASSWORD
} from 'constants/api';

const { CancelToken } = axios;
const canceledKeys = [];

export const cancelAuthAPI = () => () => {
  if (canceledKeys.length > 0) {
    canceledKeys.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Set token từ query params
export const setToken = queryParams => ({
  type: AUTH_SET_TOKEN,
  payload: {
    token: get(queryParams, QUERY_PARAM.TOKEN),
    expiresIn: toNumber(get(queryParams, QUERY_PARAM.EXPIRES_IN)),
    titleId: toNumber(get(queryParams, QUERY_PARAM.TITLE_ID))
  }
});

// Thông tin user hiện tại
export const getInfoUser = (titleId = null) => (dispatch, getState) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_INFO_USER, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/account',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    },
    titleId
  });
};

// Lấy lại mật khẩu
export const postLayLaiMatKhau = params => (dispatch) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, AUTH_LOGIN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/account/reset-password/init',
        method: 'POST',
        cancelToken,
        params
      }
    }
  });
};

// Kiểm tra phương thức đăng nhập có phải là đăng nhập nội bộ không
export const getKiemTraPhuongThucDangNhapNoiBo = () => (dispatch, getState) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/login/is-internal',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Login
export const login = data => (dispatch) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, AUTH_LOGIN, API_FAILED],
    payload: {
      client: 'login',
      request: {
        url: '/oauth/token',
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_PASSWORD}`)}`
        },
        cancelToken,
        data: {
          ...data,
          grant_type: 'password'
        }
      }
    }
  });
};

// Đổi mật khẩu
export const postDoiMatKhau = data => (dispatch, getState) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/account/change-password',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        data
      }
    }
  });
};

// Tạo mới mật khẩu
export const postTaoMoiMatKhau = data => (dispatch) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, AUTH_LOGIN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/account/reset-password/finish',
        method: 'POST',
        cancelToken,
        data
      }
    }
  });
};

// Chuyển role
export const switchRole = (titleId = null) => ({
  type: SWITCH_ROLE,
  titleId
});

// Logout
export const logout = () => (dispatch, getState) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, AUTH_LOGOUT, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/logout',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Kiểm tra URL có phải của một ứng dụng trong hệ thống hay không
export const getKiemTraURLUngDung = params => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DEFAULT, API_FAILED],
  payload: {
    request: {
      url: '/api/application/url',
      method: 'GET',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      params
    }
  }
});
