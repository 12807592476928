import axios from 'axios';
import {
  API_DATAGOV_DOMAIN,
  API_FEATURE_DOMAIN,
  API_FILE_DOMAIN,
  MOCK_API_FEATURE_DOMAIN
} from 'constants/api';
import { camelizeKeys } from 'humps';
import { getActionTypes } from 'redux-axios-middleware';
import { logout } from 'actions';
import { get, isArray, isEmpty } from 'lodash';
import { Cookies } from 'react-cookie';
import { COOKIES_NAME, COOKIES_OPTION } from 'constants/variables';
import { AUTH_LOGIN, AUTH_LOGOUT, GET_INFO_USER } from 'constants/actionTypes';
import QueryString from 'query-string';

const cookies = new Cookies();

export const apiClients = {
  default: {
    client: axios.create({
      baseURL: API_FEATURE_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/problem+json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 0
      },
      transformResponse: [function onConvertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return data;
      }]
    })
  },
  login: {
    client: axios.create({
      baseURL: API_FEATURE_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1
      },
      transformRequest: [data => QueryString.stringify(data)],
      transformResponse: [function convertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return {};
      }]
    })
  },
  mockAPI: {
    client: axios.create({
      baseURL: MOCK_API_FEATURE_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/problem+json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 0
      },
      transformResponse: [function onConvertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return data;
      }]
    })
  },
  datagov: {
    client: axios.create({
      baseURL: API_DATAGOV_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/problem+json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 0
      },
      transformResponse: [function onConvertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return data;
      }]
    })
  },
  auth: {
    client: axios.create({
      baseURL: API_FEATURE_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/problem+json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 0
      },
      transformResponse: [function convertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return data;
      }]
    })
  },
  file: {
    client: axios.create({
      baseURL: API_FILE_DOMAIN,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'text/plain, application/json, application/problem+json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 0
      },
      transformRequest: [(data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key]) {
            if (isArray(data[key])) {
              data[key].forEach((value) => {
                formData.append(key, value);
              });
            } else {
              formData.append(key, data[key], get(data, `${key}.name`));
            }
          }
        });
        return formData;
      }],
      transformResponse: [function onConvertResponse(data) {
        if (!isEmpty(data)) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        }
        return data;
      }]
    })
  },
  download: {
    client: axios.create({
      baseURL: API_FILE_DOMAIN,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1
      }
    })
  }
};

export const apiMiddlewareConfig = {
  interceptors: {
    request: [
      function onConvertRequest({ getState, dispatch, getSourceAction }, req) {
        // req: contains information about request object
        const authInfo = cookies.get(COOKIES_NAME.AUTH, COOKIES_OPTION);
        const titleId = get(authInfo, 'currentTitleId');
        if (titleId) {
          // Trường chức danh id trên header
          req.headers.titleId = titleId;
        }
        if (!isEmpty(req.params)) {
          req.paramsSerializer = params => QueryString.stringify(params);
        }
        return req;
      }
    ]
  },

  onSuccess: ({ action, next, response }, options) => {
    const nextAction = {
      type: getActionTypes(action, options)[1],
      payload: response.data,
      meta: {
        previousAction: action
      },
      origin: response
    };
    next(nextAction);
    return nextAction;
  },
  onError: ({
    action, next, error, dispatch
  }, options) => {
    const errorObj = { ...error };
    const status = get(errorObj, 'response.status');
    const type = getActionTypes(action, options)[1];
    // logout nếu gọi API bị lỗi 401 (trừ API login)
    if (status === 401 && type !== AUTH_LOGIN && type !== AUTH_LOGOUT && type !== GET_INFO_USER) {
      return dispatch(logout());
    }
    // khởi tạo giá trị mặc định của error
    let finalError = {
      data: get(errorObj, 'message', 'ERROR'),
      status: 0
    };
    // lấy error từ server nếu có
    if (!isEmpty(get(errorObj, 'response'))) {
      finalError = errorObj.response;
    }
    // log error ở môi trường development
    if (process.env.NODE_ENV !== 'production') {
      console.error('HTTP Failure in Axios', error);
    }
    const nextAction = {
      type,
      error: finalError,
      meta: {
        previousAction: action
      }
    };
    next(nextAction);
    return nextAction;
  }
};
