import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Form,
  Row
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  get,
  isEmpty,
  isFunction,
  isObject
} from 'lodash';
import {
  cancelExampleAPI,
  deleteVaiTro,
  getAllDanhSachGoiHeThong,
  getAllDanhSachVaiTro,
  getChiTietGoi
} from 'actions';
import {
  ButtonIcon,
  Container,
  ContentWrapper,
  Input,
  LoadingWrapper,
  Modal,
  Pagination,
  Select,
  Table
} from 'components/common';
import {
  DEFAULT_VALUE,
  FIRST_OPTION_SELECT,
  MAX_LENGTH,
  STRING_FORMAT
} from 'constants/variables';
import {
  alertError,
  alertSuccess,
  convertArray,
  convertResponseAPI,
  execFunction,
  getOrder,
  getReplaceString,
  getTitleTable,
  hasErrorResponseAPI,
  parseURL,
  throwError,
  workWithErrorMessages
} from 'helpers';
import messages from 'constants/messages';
import title from 'constants/popup';
import Authorization from 'components/auth/Authorization';
import {
  ROLE_DELETE,
  ROLE_POST,
  ROLE_PUT
} from 'components/auth/Permission';
import {
  colLayout,
  formDefaultProps,
  formItemDefautProps,
  rowLayout
} from 'constants/form';

const { Item: FormItem } = Form;
const { Element: AuthorizationElement } = Authorization;

class DanhSachVaiTro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'default',
      loading: false,
      isError: false,
      filter: {
        page: DEFAULT_VALUE.PAGE,
        size: DEFAULT_VALUE.PAGE_SIZE
      },
      dataVaiTro: {
        list: [],
        page: DEFAULT_VALUE.PAGE,
        pageSize: DEFAULT_VALUE.PAGE_SIZE,
        totalElements: DEFAULT_VALUE.TOTAL_PAGE,
        totalPages: 1
      }
    };
    // Kiểm tra component có đang được "mounted"
    this.mounted = true;
    // Khởi tạo ref cho form
    this.form = React.createRef();
  }

  async componentDidMount() {
    // Cập nhật query params vào state.filter
    await this.setParams();
    // Load data lần đầu
    await this.submitFilter(false);
    await this.props.actions.getAllDanhSachGoiHeThong();
  }

  componentWillUnmount() {
    // Set mounted
    this.setMounted(false);
    // Cancel API
    this.props.actions.cancelExampleAPI();
  }

  /**
   * Set mounted<AuthorizationElement permission={[TITLES_GET]}>
   * @param {Boolean} value
   * @returns {Boolean}
   * @memberof DanhSachVaiTro
  */
  setMounted = (value = true) => {
    this.mounted = value;
    return this.mounted;
  }

  /**
   * Get mounted
   * @returns {Boolean}
   * @memberof DanhSachVaiTro
   */
  getMounted = () => this.mounted

  /**
   * Set state properties
   * @param {Object} data the data input
   * @param {Function} callback the function which will be called after setState
   * @returns {void} call this.setState to update state<AuthorizationElement permission={[TITLES_GET]}>
   * @memberof DanhSachVaiTro
   */
  setStateData = (state, callback) => {
    if (!this.getMounted()) {
      return;
    }
    this.setState(state, callback);
  }

  /**
   * Click pagination
   * @param {Number} page Page
   * @param {Number} size Page size
   * @returns {void} Gọi this.submitFilter
   * @memberof DanhSachVaiTro
   */
  clickPagination = async (page, size) => {
    await this.setStateData(prevState => ({
      filter: {
        ...prevState.filter,
        page: page - 1,
        size
      }
    }));
    const shouldReset = false;
    await this.submitFilter(shouldReset);
  }

  getNamePackage = (data) => {
    const returnValue = [];
    if (!isEmpty(data)) {
      const dataPackage = data.packages;
      dataPackage.forEach((item) => {
        returnValue.push(get(item, 'name'));
      });
    }
    return returnValue.join(', ');
  }

  /**
   * Định nghĩa columns cho table danh sách example
   * @param {Number} page Page hiện tại
   * @memberof DanhSachVaiTro
   */
  columns = (page = 1) => ([
    {
      key: 'stt',
      title: 'STT',
      width: 50,
      align: 'center',
      className: 'min-width-50 bold',
      render: (text, record, index) => getOrder(page, index)
    },
    {
      key: 'packages',
      dataIndex: 'packages',
      title: 'Gói',
      align: 'left',
      className: 'min-width-200 pre-line',
      render: (text, record, index) => this.getNamePackage(record)
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Tên vai trò',
      align: 'left',
      className: 'min-width-200 pre-line'
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Mô tả',
      align: 'left',
      className: 'min-width-200 pre-line'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Thao tác',
      width: 270,
      align: 'center',
      className: 'min-width-200',
      render: id => (
        <div className="b-action">
          <AuthorizationElement permission={[ROLE_PUT]}>
            <ButtonIcon
              className="mr10 btn-edit"
              IconInstant={EditOutlined}
              onClick={this.hanleClickEdit(id)}
              text="Chỉnh sửa"
            />
          </AuthorizationElement>
          <AuthorizationElement permission={[ROLE_DELETE]}>
            <ButtonIcon
              className="btn-delete"
              IconInstant={DeleteOutlined}
              onClick={this.handleClickDelete(id)}
              text="Xóa"
            />
          </AuthorizationElement>
        </div>
      )
    }
  ])

  /**
   * Thực hiện xóa example và xử lý kết quả
   * @param {Number} id Id của example sẽ được xóa
   * @returns {void} Gọi API xóa example và xử lý theo kết quả
   * @memberof DanhSachVaiTro
   */
  deleteVaiTro = async (id) => {
    if (!id) {
      return false;
    }
    try {
      const response = await this.props.actions.deleteVaiTro(id);
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error'));
      }
      // Thông báo xóa thành công
      alertSuccess(messages.DELETE_SUCCEED);
      // Gọi API cập nhật danh sách tất cả example trong store
      // this.props.actions.getAllDanhSachExample01();
      // Reload page
      this.submitFilter(false);
    } catch (error) {
      // Thông báo message (từ API/default) khi xóa không thành công
      workWithErrorMessages({
        error,
        func: alertError,
        defaultError: messages.DELETE_FAILED
      });
    }
    return true;
  }

  /**
   * Get data danh sách example
   * @returns {void} Cập nhật state.dataVaiTro
   * @memberof DanhSachVaiTro
   */
  getDataDanhSachVaiTro = async (filter) => {
    let isError = false;
    try {
      await this.setStateData({ loading: true });
      const parsrFilter = {
        ...filter,
        keyword: filter.keyword ? get(filter, 'keyword').trim() : undefined
      };
      const response = await this.props.actions.getAllDanhSachVaiTro(parsrFilter);
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error', messages.ERROR_SYSTEM));
      }
      const dataVaiTro = convertResponseAPI(response);
      
      await this.setStateData({ dataVaiTro });
    } catch (error) {
      isError = true;
    } finally {
      this.setStateData({ isError, loading: false });
    }
    return true;
  }

  /**
   * Get footer cho [ContentWrapper]
   * @param {Object} data Data example
   * @returns {Node} Pagination hoặc null
   * @memberof DanhSachVaiTro
   */
  getFooterContentWrapper = (data, loading = false) => {
    if (isEmpty(get(data, 'list')) || loading) {
      return null;
    }
    return this.pagination(data);
  }

  /**
   * Click button "Thêm mới"
   * @returns {void} Điều hướng tới trang Thêm mới example
   * @memberof DanhSachVaiTro
   */
  handleClickAdd = () => {
    const redirectFunc = get(this.props.history, 'push');
    if (!isFunction(redirectFunc)) {
      return;
    }
    // Parse URL với query params là filter hiện tại
    const redirectPath = parseURL('/quan-ly-vai-tro/them-moi-vai-tro', { queryParams: this.state.filter });
    redirectFunc(redirectPath);
  }

  /**
   * Click button "Xem"
   * @param {String|Number} id Id example sẽ được xem
   * @returns {void} Điều hướng tới trang xem Vai Trò
   * @memberof DanhSachVaiTro
   */
  handleClickView = id => () => {
    const redirectFunc = get(this.props.history, 'push');
    if (!isFunction(redirectFunc) || !id) {
      return;
    }
    // Parse URL với query params là filter hiện tại
    const redirectPath = parseURL(`/quan-ly-vai-tro/danh-sach-vai-tro/${id}/xem-chi-tiet`, {
      queryParams: this.state.filter
    });
    redirectFunc(redirectPath);
  }

  /**
   * Click button "Chỉnh sửa"
   * @param {String|Number} id Id example sẽ được chỉnh sửa
   * @returns {void} Điều hướng tới trang Chỉnh sửa example
   * @memberof DanhSachVaiTro
   */
  hanleClickEdit = id => () => {
    const redirectFunc = get(this.props.history, 'push');
    if (!isFunction(redirectFunc) || !id) {
      return;
    }
    // Parse URL với query params là filter hiện tại
    const redirectPath = parseURL(`/quan-ly-vai-tro/${id}/chinh-sua-vai-tro`, {
      queryParams: this.state.filter
    });
    redirectFunc(redirectPath);
  }

  /**
   * Click button "Xóa"
   * @returns {void} Open popup xác nhận => xóa example và reload page
   * @memberof DanhSachVaiTro
   */
  handleClickDelete = id => () => {
    if (!id) {
      return;
    }
    Modal({
      type: 'confirm',
      title: title.DELETE_RECORD,
      onOk: () => this.deleteVaiTro(id)
    });
  }

  /**
   * Xử lý click button "Tìm kiếm"
   * @returns {void} Lưu state.filter và gọi submitFilter
   * @memberof DanhSachVaiTro
   */
  handleClickSearch = async () => {
    const validateFields = get(this.form.current, 'validateFields');
    if (!isFunction(validateFields)) {
      return;
    }
    // Validate form
    const values = await validateFields();
    // Lưu filter
    await this.setStateData(prevState => ({
      filter: {
        ...prevState.filter,
        ...values
      }
    }));
    // Submit filter
    this.submitFilter();
  }

  /**
   * Render pagination
   * @returns {Node} Render component [Pagination]
   * @memberof DanhSachVaiTro
   */
  pagination = data => (
    <Pagination
      current={get(data, 'page')}
      onChange={this.clickPagination}
      pageSize={get(data, 'pageSize')}
      total={get(data, 'totalElements')}
      totalPages={get(data, 'totalPages')}
    />
  )

  /**
   * Reset query params
   * @returns {void} Sử dụng props.history.replace
   * @memberof DanhSachVaiTro
   */
  resetQueryParams = () => {
    this.props.history.replace({ search: undefined });
  }

  /**
   * Reset submit
   * @returns {void} Reset state.filter trước khi submit
   * @memberof DanhSachVaiTro
   */
  resetSubmit = () => {
    this.setStateData(prevState => ({
      filter: {
        ...prevState.filter,
        page: DEFAULT_VALUE.PAGE,
        size: DEFAULT_VALUE.PAGE_SIZE
      }
    }));
  }

  /**
   * Set query params vào filter
   * @returns {void} Sử dụng props.queryParams
   * @memberof DanhSachVaiTro
   */
  setParams = () => {
    const queryParams = isObject(this.props.queryParams) ? this.props.queryParams : {};
    this.setStateData(prevState => ({
      filter: {
        ...prevState.filter,
        ...queryParams
      }
    }));
  }

  /**
   * Submit với state.filter
   * @param {Boolean} shouldReset Kiểm tra có reset state.filter trước khi request API không
   * @returns {void} Kiểm tra và reset filter => gọi this.getDataDanhSachVaiTro để get data
   * @memberof DanhSachVaiTro
   */
  submitFilter = async (shouldReset = true) => {
    // Kiểm tra loading
    if (this.state.loading) {
      return;
    }
    // Reset filter trước khi load data
    if (shouldReset) {
      await this.resetSubmit();
    }
    // Reset query params trước khi load data
    await this.resetQueryParams();
    await this.getDataDanhSachVaiTro(this.state.filter);
  }

  /**
   * Reset form
   * @returns {void} Reset state và form fields
   * @memberof ThemMoiChucDanh
   */
  resetSearch = async () => {
    await this.setStateData({
      loading: false,
      isError: false,
      isSubmitting: false,
      id: null,
      filter: {
        keyword: undefined,
        list_package_id: undefined
      },
      detailData: null
    });
    const resetFields = get(this.form.current, 'resetFields');
    await execFunction(resetFields);
    await this.getDataDanhSachVaiTro(this.state.filter);
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleClickSearch();
    }
  }

  render() {
    const { size } = this.state;
    const headingRight = (
      <div className="heading-search">
        <AuthorizationElement permission={[ROLE_POST]}>
          <ButtonIcon
            className="btn-uppercase btn-submit b-color-blue"
            IconInstant={PlusOutlined}
            onClick={this.handleClickAdd}
            text="Thêm mới"
          />
        </AuthorizationElement>
      </div>
    );
    const footerSearch = (
      <React.Fragment>
        <ButtonIcon
          className="btn-uppercase mr10 btn-cancel"
          iconClass="icon-tim-kiem-lai"
          onClick={this.resetSearch}
          text="Làm mới"
        />
        <ButtonIcon
          className="btn-uppercase btn-submit green"
          IconInstant={SearchOutlined}
          onClick={this.handleClickSearch}
          text="Tìm kiếm"
        />
      </React.Fragment>
    );
    const { dataVaiTro = {} } = this.state;
    return (
      <Container
        title="QUẢN LÝ VAI TRÒ"
      >
        <ContentWrapper
          className="b-search-wrapper"
          footer={footerSearch}
          title="TÌM KIẾM"
        >
          <Form
            {...formDefaultProps.vertical}
            ref={this.form}
            className="search-form"
            name="DanhSachVaiTroForm"
            scrollToFirstError
          >
            <Row {...rowLayout}>
              {/* ---------- Tên ---------- */}
              <Col {...colLayout.half}>
                <FormItem
                  {...formItemDefautProps.default}
                  initialValue={get(this.state.filter, 'keyword')}
                  label="Tên vai trò"
                  name="keyword"
                  onKeyDown={this.onKeyDown}
                  rules={[
                    {
                      max: MAX_LENGTH.NAME,
                      message: getReplaceString(messages.MAX_INPUT, [
                        { format: STRING_FORMAT.LENGTH, value: MAX_LENGTH.NAME }
                      ])
                    }
                  ]}
                  validateFirst
                >
                  <Input name="tenVaiTro" placeholder="Nhập tên vai trò" />
                </FormItem>
              </Col>
              <Col {...colLayout.half}>
                <FormItem
                  {...formItemDefautProps.default}
                  // eslint-disable-next-line max-len
                  initialValue={isEmpty(get(this.state.filter, 'list_package_id')) ? undefined : Number(get(this.state.filter, 'list_package_id'))}
                  label="Gói hệ thống"
                  name="list_package_id"
                >
                  <Select
                    dataSelect={convertArray(get(this.props.dsGoiHeThong, 'content'), {
                      firstImp: FIRST_OPTION_SELECT.GOI_HE_THONG
                    })}
                    fieldsName={{
                      value: 'id',
                      name: 'name'
                    }}
                    placeholder="- Chọn gói hệ thống -"
                    size={size}
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </ContentWrapper>
        <ContentWrapper
          footer={this.getFooterContentWrapper(dataVaiTro, this.state.loading)}
          headingRight={headingRight}
          title="DANH SÁCH VAI TRÒ"
        >
          <LoadingWrapper
            isEmpty={isEmpty(get(dataVaiTro, 'list'))}
            isError={this.state.isError}
            loading={this.state.loading}
          >
            <p className="description-table mb10">
              {getTitleTable(
                get(dataVaiTro, 'page', 1),
                get(dataVaiTro, 'pageSize', 1),
                get(dataVaiTro, 'totalElements', 0),
              )}
            </p>
            <Table
              bordered
              className="table-wrapper-custom"
              columns={this.columns(get(dataVaiTro, 'page'))}
              dataSource={get(dataVaiTro, 'list')}
              rowKey={record => get(record, 'id')}
            />
          </LoadingWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}

DanhSachVaiTro.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  dataQuanHuyen: PropTypes.arrayOf(PropTypes.object),
  dsGoiHeThong: PropTypes.objectOf(PropTypes.any),
  dsChucDanh: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  queryParams: PropTypes.objectOf(PropTypes.any)
};

DanhSachVaiTro.defaultProps = {
  dataQuanHuyen: [],
  dsGoiHeThong: {},
  dsChucDanh: {},
  queryParams: null
};

const mapStateToProps = state => ({
  dataQuanHuyen: state.common.dataQuanHuyen,
  dsGoiHeThong: state.common.dsGoiHeThong,
  dsChucDanh: state.quanLyChucDanh.dsChucDanh
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    cancelExampleAPI,
    deleteVaiTro,
    getAllDanhSachVaiTro,
    getAllDanhSachGoiHeThong,
    getChiTietGoi
  }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DanhSachVaiTro);
