/* -------- Helper functions về number -------- */
import {
  get,
  isEmpty,
  isInteger,
  isNaN,
  isString
} from 'lodash';
import {
  REGEX_PATTERN
} from 'constants/variables';
/**
 * Convert một giá trị sang number
 * @param {*} value
 * @returns {Number} result
 */
export function toNumber(value) {
  if (isNaN(Number(value))) {
    return undefined;
  }
  return Number(value);
}

/**
 * Validate number cho form
 * @param {Object} rule
 * @param {String} message Message chính
 * @param {Object} options Optional params
 * @param {Object} options.integer Data ({value, message}) để kiểm tra giá trị là integer hay không
 * @param {Object} options.max Data ({value, message}) để kiểm tra giá trị nhỏ hơn max hay không
 * @param {Object} options.min Data ({value, message}) để kiểm tra giá trị lớn hơn min hay không
 * @returns {Function} Custom validate function
 */
export const validateNumber = (message, options) => (rule, value) => {
  if (!value) {
    return Promise.resolve();
  }
  const mainMessage = message || rule.message;
  const newValue = Number(value);
  if (isNaN(newValue)) {
    return Promise.reject(mainMessage);
  }
  if (isEmpty(options)) {
    return Promise.resolve();
  }
  // check integer
  const integer = get(options, 'integer', {});
  if (!isEmpty(integer) && !isInteger(newValue)) {
    return Promise.reject(get(integer, 'message') || mainMessage);
  }
  // check max
  const max = get(options, 'max', {});
  if (isInteger(get(max, 'value')) && newValue > get(max, 'value')) {
    return Promise.reject(get(max, 'message') || mainMessage);
  }
  // check min
  const min = get(options, 'min', {});
  if (isInteger(get(min, 'value')) && newValue < get(min, 'value')) {
    return Promise.reject(get(min, 'message') || mainMessage);
  }
  return Promise.resolve();
};

/**
 * Get số thứ tự trong danh sách
 * @param {Number} [page=1]
 * @param {Number} [index=0]
 * @param {Number} [size=10]
 * @returns {String}
 */
export function getOrder(page = 1, index = 0, size = 10) {
  const num = ((page - 1) * size + index + 1);
  return num;
}

/**
 * Chuyển đổi một số hiển thị dạng money
 * @param {*} value Giá trị cần chuyển đổi
 * @returns {String} Giá trị được chuyển đổi dạng money
 */
export function convertNumberToMoneyFormat(value) {
  if (!value) {
    return '';
  }
  const isIntergerValue = isInteger(value);
  const isStringValue = isString(value);
  let valueMoney = value;
  if (isIntergerValue) {
    valueMoney = valueMoney.toString();
  }
  if (!isIntergerValue && !isStringValue) {
    return '';
  }
  return `${valueMoney}`.replace(REGEX_PATTERN.FORMAT_VIEW_MONEY, ',');
}

/**
 * Trả ra chuỗi số được làm tròn số thập phân
 * @export
 * @param {*} value Giá trị cần chuyển đổi
 * @param {Number} fractionDigits Số con số được làm tròn ở phần thập phân
 * @returns {Number}
 */
export function toFixed(value, fractionDigits) {
  const valueNumber = toNumber(value);
  if (!valueNumber) {
    return valueNumber;
  }
  const valueFixed = valueNumber.toFixed(fractionDigits);
  return valueFixed;
}
