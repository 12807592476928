/* ------ Config API sử dụng trong hệ thống ------ */
const env = process.env || {};
const array = [
  'REACT_APP_API_AUTH_DOMAIN',
  'REACT_APP_API_FEATURE_DOMAIN',
  'REACT_APP_API_FILE_DOMAIN',
  'REACT_APP_CLIENT_ID',
  'REACT_APP_CLIENT_PASSWORD',
  'REACT_APP_AUTH_DOMAIN',
  'REACT_APP_APP_NAME',
  'REACT_APP_AUTH_LOGIN_PATH',
  'REACT_APP_AUTH_PROFILE_PATH',
  'REACT_APP_REDIRECT_CALLBACK_DOMAIN',
  'REACT_APP_API_LOGIN_SSO_PATH',
  'REACT_APP_API_LOGOUT_SSO_PATH',
  'REACT_APP_TITLE_APP',
  'REACT_APP_TITLE_LOGO',
  'REACT_APP_MOCK_API_FEATURE_DOMAIN',
  'REACT_APP_API_DATAGOV_DOMAIN'
];

array.forEach((name) => {
  if (!env[name]) {
    console.warn(`The environment variable ${name} is missing, use default instead.`);
  }
});

const API_DATAGOV_DOMAIN = env.REACT_APP_API_DATAGOV_DOMAIN || 'https://dw-demo.projects.greenglobal.vn';
const API_AUTH_DOMAIN = env.REACT_APP_API_AUTH_DOMAIN || '';
const API_FEATURE_DOMAIN = env.REACT_APP_API_FEATURE_DOMAIN || '';
const API_FILE_DOMAIN = env.REACT_APP_API_FILE_DOMAIN || 'http://file.example.com';
const AUTH_DOMAIN = env.REACT_APP_AUTH_DOMAIN || 'http://auth.example.com';
const APP_NAME = env.REACT_APP_APP_NAME || 'dqs-fe';
const API_SSO_PATH = env.REACT_APP_API_LOGIN_SSO_PATH || '/cas/token';
const API_LOGOUT_SSO_PATH = env.REACT_APP_API_LOGOUT_SSO_PATH || '/cas/logout';
const CLIENT_ID = env.REACT_APP_CLIENT_ID || '';
const CLIENT_PASSWORD = env.REACT_APP_CLIENT_PASSWORD || '';
const AUTH_LOGIN_PATH = env.REACT_APP_AUTH_LOGIN_PATH || '/login';
const AUTH_PROFILE_PATH = env.REACT_APP_AUTH_PROFILE_PATH || '/quan-ly-nguoi-dung/thong-tin-nguoi-dung';
const REDIRECT_CALLBACK_DOMAIN = env.REACT_APP_REDIRECT_CALLBACK_DOMAIN || 'https://jsonblob.com';
const TITLE_APP = env.REACT_APP_TITLE_APP || '';
const TITLE_LOGO = env.REACT_APP_TITLE_LOGO || '';
const MOCK_API_FEATURE_DOMAIN = env.REACT_APP_MOCK_API_FEATURE_DOMAIN || 'https://jsonblob.com';

export {
  API_AUTH_DOMAIN,
  API_FEATURE_DOMAIN,
  API_DATAGOV_DOMAIN,
  API_FILE_DOMAIN,
  CLIENT_ID,
  CLIENT_PASSWORD,
  API_SSO_PATH,
  API_LOGOUT_SSO_PATH,
  AUTH_DOMAIN,
  APP_NAME,
  AUTH_LOGIN_PATH,
  AUTH_PROFILE_PATH,
  REDIRECT_CALLBACK_DOMAIN,
  TITLE_APP,
  TITLE_LOGO,
  MOCK_API_FEATURE_DOMAIN
};
