/* -------- Danh mục actions -------- */
import {
  API_CHINHSUAQUYEN,
  API_CHITIETQUYEN,
  API_FAILED,
  API_FETCH,
  API_THEMMOIQUYEN,
  API_XOAQUYEN,
  GET_DATA_DANHSACHQUYEN
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelDanhSachQuyenAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách Quyền
export const getAllDanhSachQuyen = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DANHSACHQUYEN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/quyen',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Chi tiết Quyền
export const getChiTietQuyen = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHITIETQUYEN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: `/api/quyen/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Thêm mới Quyền
export const postThemMoiQuyen = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOIQUYEN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/quyen',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa Quyền
export const putChinhSuaQuyen = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_CHINHSUAQUYEN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/quyen/${id}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Xóa Quyền
export const deleteQuyen = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_XOAQUYEN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/quyen/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
