/* -------- Reducer cho authentication-------- */
import { Cookies } from 'react-cookie';
import { get } from 'lodash';
import {
  COOKIES_NAME,
  COOKIES_OPTION
} from 'constants/variables';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
  GET_INFO_USER,
  SWITCH_ROLE
} from 'constants/actionTypes';
import {
  toNumber
} from 'helpers';

const cookies = new Cookies();

const initialState = cookies.get(COOKIES_NAME.AUTH, COOKIES_OPTION) || {
  isAuthenticated: false,
  token: {
    type: 'Bearer',
    accessToken: null,
    expiresIn: 0
  },
  user: {},
  titles: [],
  currentTitleId: null,
  currentTitle: {},
  permission: []
};

export default function auth(state = initialState, action = {}) {
  let authState = { ...state };
  let isUpdatedAuth = false;
  let isReset = false;
  let currentTitleId = null;
  let currentTitle = {};
  let titles = get(authState, 'titles') || [];
  let permission = [];
  switch (action.type) {
    // Set token
    case AUTH_SET_TOKEN:
      currentTitleId = get(action, 'payload.titleId') || null;
      authState = {
        ...authState,
        isAuthenticated: !!get(action, 'payload.token'),
        token: {
          ...authState.token,
          accessToken: get(action, 'payload.token'),
          expiresIn: get(action, 'payload.expiresIn')
        },
        currentTitleId
      };
      isUpdatedAuth = true;
      break;
      // Login
    case AUTH_LOGIN:
      authState = {
        ...state,
        isAuthenticated: !!get(action, 'payload.accessToken'),
        token: {
          ...state.token,
          accessToken: get(action, 'payload.accessToken'),
          expiresIn: get(action, 'payload.expiresIn')
        }
      };
      isUpdatedAuth = true;
      isReset = true;
      break;
    // Lưu thông tin user hiện tại(từ API /api/account)
    case GET_INFO_USER:
      // Danh sách chức danh
      titles = get(action, 'payload.titles') || [];
      // Dhức danh id
      currentTitleId = toNumber(get(action, 'meta.previousAction.titleId')) || null;
      // Lấy chức danh hiện tại theo currentTitleId hoặc theo chỉ định chức danh mặc định default
      currentTitle = titles.find((title) => {
        const isCurrentTitle = currentTitleId !== null && title.id === currentTitleId;
        const isDefaultTitle = currentTitleId === null && title.default;
        return isCurrentTitle || isDefaultTitle;
      });
      // Lấy danh sách mã quyền của chức danh hiện tại
      // permission = get(currentTitle, 'role.permissions', []).map(permissions => get(permissions, 'permission.code'));
      permission = get(action, 'payload.permissionCodes', []);
      authState = {
        ...authState,
        user: get(action, 'payload'),
        titles,
        currentTitleId: currentTitleId || get(currentTitle, 'id'),
        currentTitle,
        permission
      };
      isUpdatedAuth = true;
      break;
    // Chuyển title
    case SWITCH_ROLE:
      currentTitleId = action.titleId;
      // lấy chức danh hiện tại theo currentTitleId
      currentTitle = titles.find(title => currentTitleId && title.id === currentTitleId);
      // Lấy danh sách mã quyền của chức danh hiện tại
      // permission = get(currentTitle, 'role.permissions', []).map(permissions => get(permissions, 'permission.code'));
      permission = get(action, 'payload.permissionCodes', []);
      authState = {
        ...authState,
        currentTitleId,
        currentTitle,
        permission
      };
      isUpdatedAuth = true;
      break;
    // Logout
    case AUTH_LOGOUT:
      authState = {
        isAuthenticated: false,
        token: {
          type: 'Bearer',
          accessToken: null
        },
        user: {},
        titles: [],
        currentTitleId: null,
        currentTitle: {},
        permission: []
      };
      isUpdatedAuth = true;
      break;
    default:
  }
  // Cookies option
  const cookiesOption = {
    ...COOKIES_OPTION,
    // Hạn cookie theo expiresIn
    maxAge: get(authState, 'token.expiresIn') || COOKIES_OPTION.maxAge
  };
  // Xóa cookies
  if (isReset) {
    cookies.remove(COOKIES_NAME.AUTH, cookiesOption);
  }
  if (isUpdatedAuth) {
    // Lưu các thông tin chính user vào cookies (không lưu toàn bộ để tránh tràn cookies)
    const cookiesOption = {
      ...COOKIES_OPTION,
      // Hạn cookie theo expiresIn
      maxAge: get(authState, 'token.expiresIn') || COOKIES_OPTION.maxAge
    };
    cookies.set(COOKIES_NAME.AUTH, {
      isAuthenticated: authState.isAuthenticated,
      token: authState.token,
      currentTitleId: authState.currentTitleId
    }, cookiesOption);
  }
  return authState;
}
