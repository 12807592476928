import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Col,
  Form,
  Row,
  Transfer
} from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import {
  get,
  isEmpty
} from 'lodash';
import {
  cancelQuanLyNguoiDungAPI,
  getAllDanhSachChucDanh,
  getAllDanhSachVaiTro,
  postPhanBoVaiTroVaoNhomChucDanh
} from 'actions';
import {
  ButtonIcon,
  Container,
  ContentWrapper,
  LoadingWrapper,
  Select
} from 'components/common';
import {
  alertError,
  alertSuccess,
  execFunction,
  hasErrorResponseAPI,
  throwError,
  workWithErrorMessages
} from 'helpers';
import {
  colLayout,
  formDefaultProps,
  formItemDefautProps,
  rowLayout
} from 'constants/form';
import messages from 'constants/messages';

const FormItem = Form.Item;
const nameForm = {
  CHUC_DANH: 'chucDanh'
};

class PhanBoVaiTroVaoNhomChucDanh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      isloadingTransfer: false,
      isError: false,
      isSubmitting: false,
      [nameForm.CHUC_DANH]: undefined,
      dataValue: {}
    };
    // Kiểm tra component có đang được "mounted"
    this.mounted = true;
    // Khởi tạo ref cho form
    this.form = React.createRef();
  }

  async componentDidMount() {
    const params = {
      size: 100
    };
    await this.props.actions.getAllDanhSachChucDanh(params);
  }

  componentWillUnmount() {
    // Set mounted
    this.setMounted(false);
    // Cancel API
    this.props.actions.cancelQuanLyNguoiDungAPI();
  }
  
  /**
   * Set mounted
   * @param {Boolean} value
   * @returns {Boolean}
   * @memberof PhanBoVaiTroVaoNhomChucDanh
  */
  setMounted = (value = true) => {
    this.mounted = value;
    return this.mounted;
  }

  /**
   * Get mounted
   * @returns {Boolean}
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  getMounted = () => this.mounted

  /**
   * Set state properties
   * @param {Object} data the data input
   * @param {Function} callback the function which will be called after setState
   * @returns {void} call this.setState to update state
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  setStateData = (state, callback) => {
    if (!this.getMounted()) {
      return;
    }
    this.setState(state, callback);
  }

  onChangeSelect = (name, value) => {
    this.setStateData({
      [name]: value
    }, () => {
      this.getData();
    });
  }

  /**
   * Reload Phân bổ chức năng vào gói
   * @returns {void} window.location.reload()
   * @memberof PhanBoChucNangVaoGoiHeThong
   */
  reloadPage = async () => {
    window.location.reload();
  }

  getData = async () => {
    this.setStateData({ isloadingTransfer: true });
    try {
      const id = this.state[nameForm.CHUC_DANH];
      const params = {
        list_title_id: id
      };
      const response = await this.props.actions.getAllDanhSachVaiTro();
      
      const response1 = await this.props.actions.getAllDanhSachVaiTro(params);
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error', messages.ERROR_SYSTEM));
      }
      const chucDanhDuocPhanBo = [];
      const getArrVaiTro = response1 && response1.payload && response1.payload.content;
      getArrVaiTro.forEach((element) => {
        if (element) {
          chucDanhDuocPhanBo.push(element.id);
        }
      });
      const chucDanhDuocPhanBoCheckTrung = chucDanhDuocPhanBo.filter(
        (value, index, arr) => chucDanhDuocPhanBo.indexOf(value) === index
      );
      this.setStateData({
        dataValue: {
          ...this.state.dataValue,
          danhSachChucDanh: response.payload || [],
          danhSachChucDanhDuocPhanBo: chucDanhDuocPhanBoCheckTrung || []
        }
      });
    } catch (error) {
      return throwError(error);
    } finally {
      this.setStateData({ isloadingTransfer: false });
    }
    
    return true;
  }

  
  /**
   * Cập nhật chọn dữ liệu trong component transfer
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  handleChange = (targetKeys, direction, moveKeys) => {
    this.setStateData({
      dataValue: {
        ...this.state.dataValue,
        danhSachChucDanhDuocPhanBo: targetKeys
      }
    });
  };

  /**
   * Hiển thị dữ liệu trên conponent transfer
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  renderItem = (item) => {
    const customLabel = (
      <span className="custom-item">
        {item.name}
      </span>
    );

    return {
      label: customLabel, // for displayed item
      value: item.ten // for title and filter matching
    };
  };

  /**
   * Submit gửi dữ liệu
   * @param {Object} e Event object
   * @returns {void} Validate, nếu không còn lỗi thì gọi API, ngược lại thì dừng lại
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  handleSubmit = async () => {
    const id = this.state[nameForm.CHUC_DANH];
    if (!id && id === -1) {
      return false;
    }

    this.setStateData({ isSubmitting: true });
    try {
      const data = {
        role: this.state.dataValue.danhSachChucDanhDuocPhanBo,
        id
      };
      const response = await this.props.actions.postPhanBoVaiTroVaoNhomChucDanh(data);
      // eslint-disable-next-line no-console
      if (hasErrorResponseAPI(response)) {
        return alertError(messages.ERROR_SYSTEM);
      }
      
      alertSuccess(messages.SAVE_SUCCEED);
    } catch (error) {
      workWithErrorMessages({
        error,
        func: alertError,
        defaultError: messages.ERROR_SYSTEM
      });
    } finally {
      this.setStateData({ isSubmitting: false });
    }

    return true;
  }

  /**
   * Reset dữ liệu về trạng thái ban đầu
   * @returns {void} gọi lại hàm get dữ liệu
   * @memberof PhanBoVaiTroVaoNhomChucDanh
   */
  cancelForm = () => {
    this.resetForm();
  }

  /**
   * Reset form
   * @returns {void} Reset state và form fields
   * @memberof xemChiTietChucDanh
   */
  resetForm = async () => {
    await this.setStateData({
      isloading: false,
      isloadingTransfer: false,
      isError: false,
      isSubmitting: false,
      [nameForm.CHUC_DANH]: undefined,
      dataValue: {}
    });
    const resetFields = get(this.form.current, 'resetFields');
    await execFunction(resetFields);
  }

  render() {
    const { dataValue } = this.state;
    return (
      <Container
        containerClass="form-page"
        title="PHÂN BỔ VAI TRÒ VÀO NHÓM CHỨC DANH"
      >
        <LoadingWrapper
          isError={this.state.isError}
          loading={this.state.isloading}
        >
          <ContentWrapper
            footer={(
              <Row {...rowLayout}>
                <Col {...colLayout.full}>
                  <div className="btn-submit-group">
                    <ButtonIcon
                      className="m5 btn-cancel"
                      disabled={this.state.isSubmitting}
                      IconInstant={CloseOutlined}
                      onClick={this.reloadPage}
                      text="Hủy"
                    />
                    <ButtonIcon
                      className="m5 btn-submit green"
                      disabled={this.state.isSubmitting}
                      IconInstant={SaveOutlined}
                      loading={this.state.isSubmitting}
                      onClick={this.handleSubmit}
                      text="Lưu"
                    />
                  </div>
                </Col>
              </Row>
            )}
          >
            <Row {...rowLayout}>
              <Col {...colLayout.half}>
                <Form
                  {...formDefaultProps.vertical}
                  ref={this.form}
                  className="form-container mb20"
                  name="PhanBoVaiTroVaoNhomChucDanh"
                  scrollToFirstError
                >
                  <FormItem
                    {...formItemDefautProps.default}
                    initialValue={this.state[nameForm.CHUC_DANH] || undefined}
                    label="Chức danh"
                    name={nameForm.CHUC_DANH}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Select
                      dataSelect={get(this.props.dsChucDanh, 'content', [])}
                      fieldsName={{
                        value: 'id',
                        name: 'name'
                      }}
                      name={nameForm.CHUC_DANH}
                      onChange={value => this.onChangeSelect(nameForm.CHUC_DANH, value)}
                      placeholder="- Chọn chức danh -"
                      style={{ width: '98.5%' }}
                    />
                  </FormItem>
                </Form>
              </Col>
            </Row>
            <Row {...rowLayout}>
              <Col {...colLayout.full}>
                <LoadingWrapper
                  loading={this.state.isloadingTransfer}
                >
                  <Transfer
                    className={['hide-lefttitle-danhsachchucdanh']}
                    dataSource={
                      !isEmpty(dataValue.danhSachChucDanh)
                        ? get(dataValue.danhSachChucDanh, 'content') : []
                    }
                    listStyle={{
                      width: 'calc(50% - 20px)',
                      height: 300,
                      minWidth: 300,
                      marginBottom: 10
                    }}
                    onChange={this.handleChange}
                    render={this.renderItem}
                    rowKey={record => record.id}
                    targetKeys={
                      !isEmpty(dataValue.danhSachChucDanhDuocPhanBo)
                        ? dataValue.danhSachChucDanhDuocPhanBo : []
                    }
                    titles={['Danh sách vai trò', 'Đã chọn']}
                  />
                </LoadingWrapper>
              </Col>
            </Row>
          </ContentWrapper>
        </LoadingWrapper>
      </Container>
    );
  }
}

PhanBoVaiTroVaoNhomChucDanh.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  dsChucDanh: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  queryParams: PropTypes.objectOf(PropTypes.any)
};

PhanBoVaiTroVaoNhomChucDanh.defaultProps = {
  dsChucDanh: {},
  queryParams: null
};

const mapStateToProps = state => ({
  dsChucDanh: state.quanLyChucDanh.dsChucDanh
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    cancelQuanLyNguoiDungAPI,
    getAllDanhSachVaiTro,
    getAllDanhSachChucDanh,
    postPhanBoVaiTroVaoNhomChucDanh
  }, dispatch)
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(PhanBoVaiTroVaoNhomChucDanh));
