/* -------- Reducer quản lý chức danh -------- */
import {
  GET_DATA_DANHSACHCHUCDANH
} from 'constants/actionTypes';

const initialState = {
  dsChucDanh: {}
};

export default function quanLyChucDanh(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_DANHSACHCHUCDANH:
      return {
        ...state,
        dsChucDanh: action.payload || {}
      };
    default:
      return state;
  }
}
