/* -------- Danh mục actions -------- */
import {
  API_CHINHSUATAIKHOAN,
  API_CHITIETTAIKHOAN,
  API_FAILED,
  API_FETCH,
  API_TAIKHOAN,
  API_THEMMOITAIKHOAN,
  GET_DATA_DANHSACHTAIKHOAN
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelDanhSachTaiKhoanAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách Tài khoản
export const getAllDanhSachTaiKhoan = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DANHSACHTAIKHOAN, API_FAILED],
    payload: {
      client: 'login',
      request: {
        url: '/api/phan-quyen/account',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Chi tiết Tài Khoản
export const getThongTinTaiKhoan = id => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_CHITIETTAIKHOAN, API_FAILED],
    payload: {
      client: 'login',
      request: {
        url: `/api/nguoiDung/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Tài Khoản
export const getTaiKhoanNguoiDung = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_TAIKHOAN, API_FAILED],
    payload: {
      client: 'auth',
      request: {
        url: '/api/nguoiDung',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Thêm mới Tài Khoản
export const postThemMoiTaiKhoan = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_THEMMOITAIKHOAN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: '/api/create-nguoi-dung',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa Tài Khoản
export const putChinhSuaTaiKhoan = (id, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_CHINHSUATAIKHOAN, API_FAILED],
  payload: {
    client: 'auth',
    request: {
      url: `/api/account/${id}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
