/* -------- Reducer cho bảo mật dữ liệu -------- */
import {
  API_CHITIET_NGUOI_DUNG,
  API_TAIKHOAN,
  GET_DANH_SACH_NGUOI_DUNG
} from 'constants/actionTypes';

const initialState = {
  dsNguoiDung: {},
  thongTinNguoiDung: {}
};

export default function quanLyNguoiDung(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DANH_SACH_NGUOI_DUNG:
      return {
        ...state,
        dsNguoiDung: action.payload || {}
      };
    case API_TAIKHOAN:
      return {
        ...state,
        nguoiDung: action.payload || {}
      };
    case API_CHITIET_NGUOI_DUNG:
      return {
        ...state,
        thongTinNguoiDung: action.payload || {}
      };
    default:
      return state;
  }
}
