import {
  STRING_FORMAT
} from 'constants/variables';

/* ------ Messages sử dụng trong hệ thống ------ */
export default {
  // Common
  NO_DATA: 'Không có dữ liệu',
  ERROR_SYSTEM: 'Có lỗi trong quá trình tải dữ liệu',
  ENTER_INPUT_TO_SEARCH: 'Nhập để tìm kiếm',
  // Thông báo
  NOTIFICATION_TITLE: 'Thông báo',
  SAVE_SUCCEED: 'Lưu thành công',
  SAVE_FAILED: 'Lưu không thành công',
  SEND_SUCCEED: 'Gửi thành công',
  SEND_FAILED: 'Gửi không thành công',
  DELETE_SUCCEED: 'Xóa thành công',
  DELETE_FAILED: 'Xóa không thành công',
  RESET_PASSWORD_SUCCEED: 'Mật khẩu mới đang được gửi về email người dùng!',
  INVALID_CONFIRM_PASSWORD: 'Hai mật khẩu không giống nhau.\n',
  INVALID_PASSWORD: `Mật khẩu phải có từ 8-50 ký tự.
    Dùng ít nhất một chữ số, một chữ hoa, một chữ thường, một ký tự đặc biệt và không chứa khoảng trắng.\n`,
  // Vaidation
  REQUIRED_FIELD: 'Bạn không được bỏ trống trường này\n',
  AUTH_FAILED: 'Hệ thống đang xảy ra sự cố. Vui lòng đăng nhập lại sau\n',
  LOGIN_FAILED: 'Tài khoản hoặc mật khẩu của bạn không hợp lệ. Vui lòng thử lại\n',
  IN_VALID_EMAIL: 'Email không hợp lệ\n',
  INVALID_TYPE_FILE: `File không hợp lệ. Vui lòng chỉ nhập các file ${STRING_FORMAT.TYPE}\n`,
  INVALID_FILE: `Hệ thống hỗ trợ định dạng file ${STRING_FORMAT.TYPE}, dung lượng <= ${STRING_FORMAT.SIZE}MB\n`,
  MAX_INPUT: `Trường này không được nhập quá ${STRING_FORMAT.LENGTH} kí tự\n`,
  MIN_INPUT: `Trường này không được nhập ít hơn ${STRING_FORMAT.LENGTH} kí tự\n`,
  MIN_NUMBER: `Trường này không được nhập nhỏ hơn ${STRING_FORMAT.NUMBER}\n`,
  INVALID_BETWEEN_NUMBER: `Vui lòng nhập giá trị từ ${STRING_FORMAT.NUMBER} đến ${STRING_FORMAT.NUMBER}`,
  MAX_AVATAR_SIZE: `Vui lòng nhập file tối da ${STRING_FORMAT.SIZE} MB\n`,
  INVALID_PHONE_NUMBER: 'Số điện thoại không hợp lệ\n',
  INVALID_YEAR_RANGE: 'Từ năm/đến năm không hợp lệ\n',
  INVALID_NUMBER: 'Trường này chỉ được nhập số\n',
  INVALID_URL: 'Url không hợp lệ\n',
  DU_LIEU_KHONG_HOP_LE: 'Dữ liệu không hợp lệ\n',
  // Danh sách
  TITLE_TABLE: `Hiển thị kết quả từ ${STRING_FORMAT.FIRST} - ${STRING_FORMAT.LAST}
    trên tổng ${STRING_FORMAT.TOTAL} kết quả`
};
