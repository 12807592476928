/* -------- File actions -------- */
import {
  API_DEFAULT,
  API_FAILED,
  API_FETCH
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledKeys = [];

export const cancelFileAPI = () => () => {
  if (canceledKeys.length > 0) {
    canceledKeys.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Download file
export const donwload = fileId => (dispatch, getState) => {
  canceledKeys.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledKeys[canceledKeys.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'download',
      request: {
        url: `/api/file/${fileId}/download`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Upload file
export const uploadFile = file => (dispatch, getState) => {
  const data = { file };
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'file',
      request: {
        url: '/api/file/upload',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        data
      }
    }
  });
};

// Upload multiple files
export const uploadFiles = files => (dispatch, getState) => {
  const data = { files };
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      client: 'file',
      request: {
        url: '/api/file/upload-multiple',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        data
      }
    }
  });
};
