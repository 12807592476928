/* -------- Danh mục actions -------- */
import {
  API_DEFAULT,
  API_FAILED,
  API_FETCH,
  GET_DATA_EXAMPLE01
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';

const { CancelToken } = axios;
const canceledList = [];

export const cancelExampleAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

// Danh sách tất cả example 01
export const getAllDanhSachExample01 = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_EXAMPLE01, API_FAILED],
    payload: {
      request: {
        url: '/api/nhom-cong-trinh',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params,
          size: 0
        }
      }
    }
  });
};

// Danh sách example 01
export const getDanhSachExample01 = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      request: {
        url: '/api/nhom-cong-trinh',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params
      }
    }
  });
};

// Chi tiết example 01
export const getChiTietExample01 = nhomCongTrinhId => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, API_DEFAULT, API_FAILED],
    payload: {
      request: {
        url: `/api/nhom-cong-trinh/${nhomCongTrinhId}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken
      }
    }
  });
};

// Thêm mới example 01
export const postExample01 = data => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DEFAULT, API_FAILED],
  payload: {
    request: {
      url: '/api/nhom-cong-trinh',
      method: 'POST',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Chỉnh sửa example 01
export const putExample01 = (nhomCongTrinhId, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DEFAULT, API_FAILED],
  payload: {
    request: {
      url: `/api/nhom-cong-trinh/${nhomCongTrinhId}`,
      method: 'PUT',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});

// Xóa example 01
export const deleteExample01 = (nhomCongTrinhId, data) => (dispatch, getState) => dispatch({
  types: [API_FETCH, API_DEFAULT, API_FAILED],
  payload: {
    request: {
      url: `/api/nhom-cong-trinh/${nhomCongTrinhId}`,
      method: 'DELETE',
      headers: {
        Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
      },
      data
    }
  }
});
