
/* -------- Reducer cho danh mục -------- */
import {
  GET_DATA_EXAMPLE01
} from 'constants/actionTypes';
import { convertResponseAPI } from 'helpers';

const initialState = {
  dataExample01: []
};

export default function example(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DATA_EXAMPLE01:
      return {
        ...state,
        dataExample01: convertResponseAPI(action).list
      };
    default:
      return state;
  }
}
