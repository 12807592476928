import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  COOKIES_NAME,
  COOKIES_OPTION
} from 'constants/variables';
import {
  get,
  intersection,
  isEmpty
} from 'lodash';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isValidCondition } from 'helpers';

const cookies = new Cookies();

const vaild = (permission, allowedPermission, orPermission = false) => {
  if (
    (!orPermission && intersection(permission, allowedPermission).length === allowedPermission.length)
    || (orPermission && intersection(permission, allowedPermission).length > 0)
  ) {
    return true;
  }

  return false;
};

const Authorization = (
  allowedPermission = [],
  isPage = false,
  allowedOrPermission,
  customCondition
) => (WrappedComponent) => {
  let dataPermission = allowedPermission;
  const dataOrPermission = allowedOrPermission;

  class WithAuthorization extends Component {
    render() {
      const {
        orPermission,
        auth,
        permission,
        children
      } = this.props;
      const parentProps = Object.assign({}, this.props);

      if (permission) {
        dataPermission = permission;
      }
      // Trường hợp có điều kiện đặc biệt
      // eslint-disable-next-line no-unused-vars
      let isValidCustomCondition = false;
      if (!isEmpty(customCondition)) {
        const isValid = isValidCondition({
          ...customCondition,
          userPermission: auth.permission
        });
        isValidCustomCondition = isValid;
      }
      if (
        (!orPermission && !dataOrPermission
          && intersection(auth.permission, dataPermission).length === dataPermission.length)
        || (orPermission && !dataOrPermission && intersection(auth.permission, orPermission).length > 0)
        || (dataOrPermission && intersection(auth.permission, dataOrPermission).length > 0)

      ) {
        if (children) {
          return children;
        }
        return <WrappedComponent {...parentProps} />;
      }

      if (isPage) {
        const authInfo = cookies.get(COOKIES_NAME.AUTH, COOKIES_OPTION);
        const accessToken = get(auth, 'token.accessToken') || get(authInfo, 'token.accessToken');
        if (!accessToken) {
          return <Redirect push to="/dang-nhap" />;
        }
        return <WrappedComponent {...parentProps} />;
      }

      return false;
    }
  }

  const mapStateToProps = state => ({
    auth: state.auth
  });

  WithAuthorization.propTypes = {
    auth: PropTypes.objectOf(PropTypes.any),
    orPermission: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any)
    ]),
    permission: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any)
    ]),
    children: PropTypes.any,
    actions: PropTypes.objectOf(PropTypes.any).isRequired
  };

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
    }, dispatch)
  });

  WithAuthorization.defaultProps = {
    auth: {},
    orPermission: undefined,
    permission: undefined,
    children: undefined
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
};

export default {
  Component: Authorization,
  Element: Authorization()(),
  vaild
};
