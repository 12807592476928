import {
  ContactsOutlined,
  ControlOutlined,
  DashboardOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileWordFilled,
  FormOutlined,
  FundOutlined,
  HistoryOutlined,
  MoneyCollectOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { APP_NAME } from 'constants/api';

/* ------ Variables sử dụng trọng application ------ */
/* ------ Common ------ */
export const FILE_URL_FORMAT = '{{domain}}/file{{url}}';
// Date format
export const DATE_FORMAT = {
  DATETIME: 'HH:mm DD/MM/YYYY',
  DATE_BEFORE_TIME: 'DD/MM/YYYY HH:mm',
  DATE: 'DD/MM/YYYY',
  TIME: 'HH:mm',
  DATETIME_NO_SPACE: 'YYYYMMDDHHmmss',
  DATE_VIEW_VN: 'ngày LL',
  DATE_EXCEL_NAME: 'DD-MM-YYYY',
  YEAR: 'YYYY'
};
// Các giá trị mặc định
export const DEFAULT_VALUE = {
  START_TIME: '00:00',
  END_TIME: '23:59',
  START_HOUR: 0,
  END_HOUR: 23,
  START_MINUTE: 0,
  END_MINUTE: 59,
  START_SECOND: 0,
  END_SECOND: 59,
  START_FILE_INDEX: 0,
  MID_FILE_INDEX: 2,
  AVATAR_CLIENT: '/images/default-avatar.png',
  IMAGE_DATAGOV: '/images/datagov.png',
  IMAGE_DQS: '/images/dqs.png',
  NUMBER_MIN: 1,
  // Số dòng mặc định của textarea
  TEXTAREA_ROWS: 3,
  // Mật khẩu tạo tài khoản
  ACCOUNT_PASSWORD: '12345678',
  // Pagination
  PAGE: 0,
  PAGE_SIZE: 10,
  // Scroll table
  TABLE_SCROLL_X: true,
  TABLE_SCROLL_Y: 480,
  // Giá trị props.decimalSeparator sử dụng cho InputNumber Antd
  INPUT_NUMBER_DECIMAL_SEPARATOR: '.',
  // Dropdown all list Danh sách chức danh ở menu Danh sách người dùng
  MAX_SIZE: 2147483647
};
// Max length của các loại input trong form
export const MAX_LENGTH = {
  INPUT: 500,
  MO_TA: 500,
  INPUT_FIELD: 255,
  NAME: 100,
  TEXTAREA: 1000,
  LARGE_TEXTAREA: 5000,
  PHONE_NUMBER: 15,
  PASSWORD: 50,
  DESCRIPTION: 255,
  MONEY: 50,
  CODE: 100,
  SEARCH_INPUT: 100
};
// Min length của các loại input trong form
export const MIN_LENGTH = {
  PASSWORD: 8
};
// Min value của các thuộc tính datetime
export const DATE_LIMIT_VALUE = {
  YEAR_MIN: 1970,
  YEAR_MAX: 9999
};
// Max value của các loại input number trong form
export const MAX_NUMBER = {
  MONEY: 999999999999,
  PERCENT: 100,
  PERCENT_ZERO: 100,
  QUANTITY: 9999,
  QUANTITY_ZERO: 9999,
  SIZE: 9999
};
// Min value của các loại input number trong form
export const MIN_NUMBER = {
  MONEY: 1000,
  PERCENT: 1,
  PERCENT_ZERO: 0,
  QUANTITY: 1,
  QUANTITY_ZERO: 0
};
// Loại InputNumber
export const TYPE_INPUT_NUMBER = {
  MONEY: 'MONEY',
  QUANTITY: 'QUANTITY',
  PERCENT: 'PERCENT',
  PERCENT_ZERO: 'PERCENT_ZERO',
  QUANTITY_ZERO: 'QUANTITY_ZERO'
};

// Giá trị đầu tiên trong select box
export const FIRST_OPTION_SELECT = {
  DON_VI: [{
    id: '',
    tenDonVi: 'Tất cả'
  }],
  CHU_DAU_TU: [{
    id: '',
    tenDonVi: 'Tất cả'
  }],
  BAN_QUAN_LY: [{
    id: '',
    tenDonVi: 'Tất cả'
  }],
  NGUON_VON_DAU_TU: [{
    id: '',
    tenNguonVonDauTu: 'Tất cả'
  }],
  LOAI_CONG_TRINH: [{
    id: '',
    tenLoaiCongTrinh: 'Tất cả'
  }],
  LINH_VUC_CONG_TRINH: [{
    id: '',
    tenLinhVucCongTrinh: 'Tất cả'
  }],
  GIAI_DOAN_THUC_HIEN: [{
    id: '',
    tenGiaiDoanThucHien: 'Tất cả'
  }],
  NHOM_CONG_TRINH: [{
    id: '',
    tenNhomCongTrinh: 'Tất cả'
  }],
  QUAN_HUYEN: [{
    code: '',
    name: 'Tất cả'
  }],
  GOI_HE_THONG: [{
    id: '',
    name: 'Tất cả'
  }],
  SELECT_COMMON: [{
    ma: null,
    moTa: '- Chọn -'
  }]
};
// Thông số cookie
export const COOKIES_NAME = {
  AUTH: `${APP_NAME}_AUTH_INFO`
};
export const COOKIES_OPTION = {
  path: '/',
  // Hạn cookies: 24 giờ
  maxAge: 86398
};
// API Status code
export const STATUS_API = {
  ERROR_VALIDATION: 417,
  SUCCESS_LIST: [200, 201]
};
// Layout type
export const LAYOUT_TYPE = {
  MASTER: 'MASTER',
  LOGIN: 'LOGIN',
  AUTH: 'AUTH'
};
// Route
// Đăng nhập
export const LOGIN_ROUTE = '/dang-nhap';
// Trang chủ
export const HOME_ROUTE = '/trang-chu';
// Những route được phép truy cập từ bên ngoài bằng token
export const ALLOWED_ACCESS_ROUTE_FROM_EXTERNAL = [
  HOME_ROUTE,
  '/thong-tin-nguoi-dung'
];
// Heading type
export const HEADING_TYPE = {
  BACKGROUND: 'BACKGROUND',
  TRANSPARENT: 'TRANSPARENT'
};
// Routes không yêu cầu đăng nhập
export const ROUTE_NO_LOGIN_LIST = [
  LOGIN_ROUTE,
  '/lay-lai-mat-khau',
  '/tao-moi-mat-khau'
];
// Định nghĩa các thông tin liên quan hiển thị message từ API
export const CUSTOM_ERROR = {
  TYPE_ERROR: {
    AUTH: ['unauthorized']
  },
  FIELD_NAME: {
    TYPE: 'error',
    MESSAGE: 'errorDescription',
    LOGOUT_SSO: 'logoutSso'
  }
};
// Screen width
export const SCREEN_WIDTH = {
  MOBILE: 575,
  TABLET: 1200,
  DESKTOP: 1366
};
// Screen type
export const SCREEN_TYPE = {
  MOBILE: 'is-mobile',
  TABLET: 'is-tablet',
  DESKTOP: 'is-desktop'
};
// String format (dùng trong form)
export const STRING_FORMAT = {
  LENGTH: '{{length}}',
  NUMBER: '{{Number}}',
  FIRST: '{{first}}',
  LAST: '{{last}}',
  TOTAL: '{{total}}',
  TYPE: '{{type}}',
  SIZE: '{{size}}',
  DOMAIN: '{{domain}}',
  URL: '{{url}}'
};
// Định dạng file hợp lệ
export const VALID_FILE_TYPE = {
  AVATAR: ['.jpeg', '.jpg', '.png'],
  DOCUMENT: ['.pdf', '.doc', '.docx', '.xlsx', '.xls', '.jpeg', '.jpg', '.png', '.ppt', '.pptx'],
  PDF: ['.pdf'],
  WORD: ['.doc', '.docx'],
  EXCEL: ['.xlsx', '.xls'],
  IMAGE: ['.jpeg', '.jpg', '.png'],
  PPT: ['.ppt', '.pptx']
};
// Max size file
export const MAX_SIZE_FILE = {
  AVATAR: 5,
  DOCUMENT_FILE: 20
};
// Icon name
export const ICON_INSTANT = {
  DESKTOP_OUTLINED: DesktopOutlined,
  FILE_DONE_OUTLINED: FileDoneOutlined,
  FILE_EXCEL_FILLED: FileExcelFilled,
  FILE_FILLED: FileFilled,
  FILE_IMAGE_FILLED: FileImageFilled,
  FILE_PDF_FILLED: FilePdfFilled,
  FILE_PPT_FILLED: FilePptFilled,
  FILE_WORD_FILLED: FileWordFilled,
  FORM_OUTLINED: FormOutlined,
  FUND_OUTLINED: FundOutlined,
  HOME_OUTLINED: DashboardOutlined,
  MONEY_COLLECT_OUTLINED: MoneyCollectOutlined,
  PROFILE: ProfileOutlined,
  USER_MANAGE: UserOutlined,
  PERMISSION: SecurityScanOutlined,
  FEATURED: ControlOutlined,
  TITLES: ContactsOutlined,
  ROLES: UsergroupAddOutlined,
  HISTORY: HistoryOutlined
};
// File type
export const FILE_TYPE = {
  FILE: 'FILE',
  PDF: 'PDF',
  WORD: 'WORD',
  EXCEL: 'EXCEL',
  IMAGE: 'IMAGE',
  PPT: 'PPT'
};
// Regex Pattern
export const REGEX_PATTERN = {
  PHONE_NUMBER: /^[0-9]+$/,
  NUMBER: /^[0-9]+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_-])[A-Za-z\d!@#$%^&*()_-]{8,50}$/g,
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g,
  CODE: /^[a-zA-Z_]+$/g,
  FORMAT_VIEW_MONEY: /\B(?=(\d{3})+(?!\d))/g
};
// Query param
export const QUERY_PARAM = {
  // Tên param sử dụng khi cần login qua hệ thống quản trị người dùng, sau đó điều hướng trở lại hệ thống
  REDIRECT_CALLBACK: 'redirect_callback',
  FROM_DOMAIN: 'from_domain',
  TOKEN: 'access_token',
  EXPIRES_IN: 'expires_in',
  TITLE_ID: 'titleId',
  ID_STRING: 'accesstoken'
};
// Định nghĩa option sử dụng cho window.open
export const WINDOW_OPEN_TOKEN_OPTION = {
  // Dùng cho trang login của hệ thống quản trị người dùng
  EXTERNAL_AUTH_LOGIN: {
    name: '_self'
  },
  // Các sites khác (khác đăng nhập)
  EXTERNAL_NORMAL_SITE: {
    name: '_blank'
  },
  // Dùng cho trang thông tin người dùng hệ thống quản trị người dùng
  EXTERNAL_AUTH_PROFILE: {
    name: '_blank'
  }
};
// Field name chung sử dụng trong hệ thống
export const FIELD_NAME = {
  ID: 'id',
  DATA: 'data',
  DATA_SOURCE: 'dataSource',
  ERROR: 'error',
  KEY: 'key',
  STT: 'stt',
  COLSPAN: 'colSpan',
  ROWSPAN: 'rowSpan',
  IS_TOTAL: 'isTotal',
  IS_GROUP: 'isGroup'
};
