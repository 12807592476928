import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigProvider } from 'antd';
import QueryString from 'query-string';
import viVN from 'antd/lib/locale-provider/vi_VN';
import 'moment/locale/vi';
import {
  get,
  isEmpty,
  isFunction
} from 'lodash';
import { RenderRoutes } from 'routes';
import 'app.less';
import 'assets/style/main.css';
import {
  cancelAuthAPI,
  getAllDanhSachExample01,
  getDanhSachQuanHuyen,
  getInfoUser,
  logout,
  setShouldLoadInitialComboboxs,
  setToken
} from 'actions';
import {
  alertError,
  hasErrorResponseAPI,
  redirectTo,
  throwError
} from 'helpers';
import {
  LoadingWrapper
} from 'components/common';
import messages from 'constants/messages';
import {
  CUSTOM_ERROR,
  QUERY_PARAM,
  WINDOW_OPEN_TOKEN_OPTION
} from 'constants/variables';
import {
  API_AUTH_DOMAIN,
  API_LOGOUT_SSO_PATH,
  REDIRECT_CALLBACK_DOMAIN
} from 'constants/api';
import { camelizeKeys } from 'humps';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.mounted = true;
  }

  async componentDidMount() {
    // Khởi tạo
    await this.initialLoad();
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.shouldLoadInitialComboboxs) {
      // Gọi API các comboboxs
      await this.getDataCombobox();
    }
  }

  componentWillUnmount() {
    // cancel all API
    this.props.actions.cancelAuthAPI();
  }

  /**
   * Set mounted
   * @param {Boolean} value
   * @returns {Boolean}
   * @memberof Login
  */
  setMounted = (value = true) => {
    this.mounted = value;
    return this.mounted;
  }

  /**
   * Get mounted
   * @returns {Boolean}
   * @memberof Login
   */
  getMounted = () => this.mounted

  /**
   * Set state properties
   * @param {Object} data the data input
   * @param {Function} callback the function which will be called after setState
   * @returns {void} call this.setState to update state
   * @memberof Login
   */
  setStateData = (state, callback) => {
    if (!this.getMounted()) {
      return;
    }
    this.setState(state, callback);
  }

  /**
   * Khởi tạo APP
   * Get data ban đầu
   * @returns {void}
   * @memberof App
   */
  initialLoad = async () => {
    let path = get(this.props.history, 'location.pathname', '/');
    try {
      const token = get(this.props.auth, 'token.accessToken');
      // Query params
      const queryParams = QueryString.parse(get(this.props.history, 'location.search'));
      // Token từ query params
      const tokenFromQueryParams = get(queryParams, QUERY_PARAM.TOKEN);
      // const titleIdFromQueryParams = toNumber(get(queryParams, QUERY_PARAM.TITLE_ID));
      if (isEmpty(token) || tokenFromQueryParams) {
        // Redirect callback của hệ thống được gửi từ hệ thống quản trị người dùng
        const redirectCallback = get(queryParams, QUERY_PARAM.REDIRECT_CALLBACK, '');
        // Split redirect callback theo domain của hệ thống quản trị người dùng
        const splitMemberPaths = redirectCallback.split(REDIRECT_CALLBACK_DOMAIN);
        // Lấy path
        path = get(splitMemberPaths, '[1]', path);
        if (!tokenFromQueryParams) {
          // throwError(messages.AUTH_FAILED);
        }
        // Set token
        await this.props.actions.setToken(queryParams);
      }
      const errorTypeFromQueryParams = get(queryParams, CUSTOM_ERROR.FIELD_NAME.TYPE);
      // Nếu tồn tại lỗi trên query params
      if (!isEmpty(errorTypeFromQueryParams)) {
        const errorData = {
          data: queryParams
        };
        throwError(errorData);
      }
      // Gọi API lấy thông tin user
      // const currentTitleId = titleIdFromQueryParams || get(this.props.auth, 'currentTitleId') || null;
      // const responseUserInfo = await this.getUserInfo(currentTitleId);
      // if (isEmpty(responseUserInfo)) {
      //   throwError(responseUserInfo);
      // }
      // Gọi API các comboboxs
      await this.getDataCombobox(true);
      // Điều hướng tời path và reset một số params liên quan việc xác thực
      const queryString = QueryString.stringify({
        ...queryParams,
        [QUERY_PARAM.REDIRECT_CALLBACK]: undefined,
        [QUERY_PARAM.FROM_DOMAIN]: undefined,
        [QUERY_PARAM.TOKEN]: undefined
      });
      await this.props.history.push({
        pathname: path,
        search: `?${queryString}`
      });
    } catch (error) {
      // set location.state.error để show ở login form
      // await this.props.history.replace({ state: { error: messages.AUTH_FAILED }, search: undefined });
      // console.log('error is: ', error);
      await this.handleErrorResponse(error);
      // logout
      // await this.props.actions.logout();
      // this.props.history.push('/dang-nhap');
    } finally {
      this.setStateData({ loading: false });
    }
  }

  /**
   * Get thông tin user khi load/reload APP
   * @returns {void} Gọi API lấy thông tin user hiện tại
   * @memberof App
   */
  getUserInfo = async (currentTitleId) => {
    const response = await this.props.actions.getInfoUser(currentTitleId);
    if (hasErrorResponseAPI(response)) {
      return null;
    }
    return get(response, 'payload');
  }

  /**
   * Get data các comboboxs trong hệ thống
   * @returns {void} Gọi API hệ thống, danh mục
   * @memberof App
   */
  getDataCombobox = async (force = false) => {
    if (!force && !this.props.shouldLoadInitialComboboxs) {
      return;
    }
    await this.props.actions.setShouldLoadInitialComboboxs(false);
    // Định nghĩa actions với conditions
    // const requestData = [
    //   // Data example 01
    //   {
    //     conditions: [],
    //     request: this.props.actions.getAllDanhSachExample01
    //   },
    //   // Data quận huyện
    //   {
    //     conditions: [],
    //     request: this.props.actions.getDanhSachQuanHuyen
    //   }
    // ];
    // // Quyền hiện tại của user
    // const userPermission = get(this.props.auth, 'permission');
    // // Kiểm tra từng action với điều kiện
    // requestData.forEach((item) => {
    //   const isValid = isValidCondition({
    //     conditions: item.conditions,
    //     userPermission
    //   });
    //   if (isValid && isFunction(item.request)) {
    //     item.request();
    //   }
    // });
  }

  /**
   * Xử lý response error
   * @param {Object} error Response error
   * @returns {void}
   * @memberof App
   */
  handleErrorResponse = async (error = null) => {
    // Hiển thị message
    const customError = camelizeKeys(error);
    const errorTypes = CUSTOM_ERROR.TYPE_ERROR.AUTH;
    const errorType = get(customError, `data.${CUSTOM_ERROR.FIELD_NAME.TYPE}`);
    const errorMessage = get(customError, `data.${CUSTOM_ERROR.FIELD_NAME.MESSAGE}`);
    const errorLogoutSSO = get(customError, `data.${CUSTOM_ERROR.FIELD_NAME.LOGOUT_SSO}`, false);
    const shouldUseCustomMessage = errorTypes.includes(errorType);
    const message = shouldUseCustomMessage ? errorMessage : messages.AUTH_FAILED;
    const token = get(this.props.auth, 'token.accessToken');
    if (!isEmpty(token)) {
      // Logout
      // console.log("token is: ", token);
      await this.props.actions.logout();
    }
    // Kiểm tra cần logout SSO khi xảy ra lỗi
    if (errorLogoutSSO) {
      const URL = `${API_AUTH_DOMAIN}${API_LOGOUT_SSO_PATH}`;
      // Open window logout SSO
      const windowSSO = redirectTo(URL, null, WINDOW_OPEN_TOKEN_OPTION.EXTERNAL_AUTH_LOGOUT_SSO);
      // Blur windown SSO
      if (isFunction(get(windowSSO, 'blur'))) {
        windowSSO.blur();
      }
      // Close windown SSO
      if (isFunction(get(windowSSO, 'close'))) {
        setTimeout(() => {
          windowSSO.close();
        }, 150);
      }
    }
    // console.log("log: ", message);
    // Thông báo message (từ API/default) khi xóa không thành công
    alertError(message);
  }

  render() {
    return (
      <LoadingWrapper loading={this.state.loading || this.props.loadingApp}>
        <ConfigProvider locale={viVN}>
          <RenderRoutes history={this.props.history} routes={this.props.routes} />
        </ConfigProvider>
      </LoadingWrapper>
    );
  }
}

App.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingApp: PropTypes.bool.isRequired,
  shouldLoadInitialComboboxs: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  loadingApp: state.common.loadingApp,
  shouldLoadInitialComboboxs: state.common.shouldLoadInitialComboboxs
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    cancelAuthAPI,
    getAllDanhSachExample01,
    getDanhSachQuanHuyen,
    getInfoUser,
    logout,
    setShouldLoadInitialComboboxs,
    setToken
  }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
