
/* -------- Reducer cho bảo mật dữ liệu -------- */
import {
  API_CHUCDANHID,
  API_CHUCNANGID,
  API_QUYENID,
  API_VAITROID,
  GET_DANH_SACH_DAC_QUYEN,
  GET_DANH_SACH_NGUOI_DUNG,
  GET_DANH_SACH_TAI_KHOAN,
  GET_DATA_DANHSACHVAITRO
} from 'constants/actionTypes';

const initialState = {
  dsTaiKhoan: {},
  dsDacQuyen: [],
  dsNguoiDung: {},
  dsChucNangID: {},
  dsVaiTro: {},
  dsQuyenID: {}
};

export default function baoMatDuLieu(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DANH_SACH_NGUOI_DUNG:
      return {
        ...state,
        dsNguoiDung: action.payload || {}
      };
    case GET_DATA_DANHSACHVAITRO:
      return {
        ...state,
        dsVaiTro: action.payload || {}
      };
    case GET_DANH_SACH_TAI_KHOAN:
      return {
        ...state,
        dsTaiKhoan: action.payload || {}
      };
    case GET_DANH_SACH_DAC_QUYEN:
      return {
        ...state,
        dsDacQuyen: action.payload || []
      };
    case API_CHUCDANHID:
      return {
        ...state,
        dsChucDanhID: action.payload || {}
      };
    case API_CHUCNANGID:
      return {
        ...state,
        dsChucNangID: action.payload || {}
      };
    case API_QUYENID:
      return {
        ...state,
        dsQuyenID: action.payload || {}
      };
    case API_VAITROID:
      return {
        ...state,
        dsVaiTroID: action.payload || {}
      };
    default:
      return state;
  }
}
