/* ------ Action type cho API sử dụng trong hệ thống ------ */
/* ------------------------------------------------------------------------- */
// Common
/* ------------------------------------------------------------------------- */
export const API_FETCH = 'API_FETCH';
export const API_DEFAULT = 'API_DEFAULT';
export const API_FAILED = 'API_FAILED';
export const SET_LOADING_APP = 'SET_LOADING_APP';
export const SET_SHOULD_LOAD_INITIAL_COMBOBOXS = 'SET_SHOULD_LOAD_INITIAL_COMBOBOXS';
export const GET_DATA_QUAN_HUYEN = 'GET_DATA_QUAN_HUYEN';
export const GET_DATA_DS_QUYEN = 'GET_DATA_DS_QUYEN';
export const GET_DATA_DAC_QUYEN = 'GET_DATA_DAC_QUYEN';

/* ------------------------------------------------------------------------- */
// Auth
/* ------------------------------------------------------------------------- */
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const GET_INFO_USER = 'GET_INFO_USER';
export const SWITCH_ROLE = 'SWITCH_ROLE';

/* ------------------------------------------------------------------------- */
// Example
/* ------------------------------------------------------------------------- */
export const GET_DATA_EXAMPLE01 = 'GET_DATA_EXAMPLE01';

/* ------------------------------------------------------------------------- */
// Bảo mật dữ liệu
/* ------------------------------------------------------------------------- */
export const GET_DANH_SACH_NGUOI_DUNG = 'GET_DANH_SACH_NGUOI_DUNG';
export const GET_DANH_SACH_TAI_KHOAN = 'GET_DANH_SACH_TAI_KHOAN';
export const GET_DANH_SACH_DAC_QUYEN = 'GET_DANH_SACH_DAC_QUYEN';
export const GET_DATA_DS_GOIHETHONG = 'GET_DATA_DS_GOIHETHONG';
export const API_CHUCDANHID = 'API_CHUCDANHID';
export const API_VAITROID = 'API_VAITROID';
export const API_POSTDANHSACHQUYEN = 'API_POSTDANHSACHQUYEN';
/* ------------------------------------------------------------------------- */
// Chức Năng
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHCHUCNANG = 'GET_DATA_NANGSACHCHUCNANG';
export const API_CHITIETCHUCNANG = 'API_CHITIETCHUCNANG';
export const API_THEMMOICHUCNANG = 'API_THEMMOICHUCNANG';
export const API_CHINHSUACHUCNANG = 'API_CHINHSUACHUCNANG';
export const API_DELETECHUCNANG = 'API_DELETECHUCNANG';
export const API_CHUCNANGID = 'API_CHUCNANGID';
export const API_CHUCNANG_IDHETHONG = 'API_CHUCNANG_IDHETHONG';
export const API_PHANBOQUYENCHUCNANG = 'API_PHANBOQUYENCHUCNANG';
/* ------------------------------------------------------------------------- */
// Chức Danh
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHCHUCDANH = 'GET_DATA_DANHSACHCHUCDANH';
export const API_CHITIETCHUCDANH = 'API_CHITIETCHUCDANH';
export const API_THEMMOICHUCDANH = 'API_THEMMOICHUCDANH';
export const API_XOACHUCDANH = 'API_XOACHUCDANH';
/* ------------------------------------------------------------------------- */
// Vai Trò
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHVAITRO = 'GET_DATA_DANHSACHVAITRO';
export const GET_DANHSACH_VAITRO = 'GET_DANHSACH_VAITRO';
export const API_CHITIETVAITRO = 'API_CHITIETVAITRO';
/* ------------------------------------------------------------------------- */
// Hệ Thống
/* ------------------------------------------------------------------------- */
export const API_THEMMOIGOIHETHONG = 'API_THEMMOIGOIHETHONG';
export const API_CHITIETGOI = 'API_CHITIETGOI';
export const API_CHINHSUAGOIHETHONG = 'API_CHINHSUAGOIHETHONG';
export const API_XOAGOIHETHONG = 'API_XOAGOIHETHONG';
// Quyền
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHQUYEN = 'GET_DATA_DANHSACHQUYEN';
export const API_CHITIETQUYEN = 'API_CHITIETQUYEN';
export const API_THEMMOIQUYEN = 'API_THEMMOIQUYEN';
export const API_QUYENID = 'API_QUYENID';
export const API_CHINHSUAQUYEN = 'API_CHINHSUAQUYEN';
export const API_XOAQUYEN = 'API_XOAQUYEN';
// Người Dùng
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHTAIKHOAN = 'GET_DATA_DANHSACHTAIKHOAN';
export const API_CHITIETTAIKHOAN = 'API_CHITIETTAIKHOAN';
export const API_THEMMOITAIKHOAN = 'API_THEMMOITAIKHOAN';
export const API_CHINHSUATAIKHOAN = 'API_CHINHSUATAIKHOAN';
export const API_TAIKHOAN = 'API_TAIKHOAN';
export const NGUOI_DUNG = 'NGUOI_DUNG';
export const API_CHITIET_NGUOI_DUNG = 'API_CHITIET_NGUOI_DUNG';
// Lịch sử truy cấp
/* ------------------------------------------------------------------------- */
export const GET_DATA_DANHSACHLICHSUTRUYCAP = 'GET_DATA_DANHSACHLICHSUTRUYCAP';
export const GET_CHI_TIET_LICH_SU = 'GET_CHI_TIET_LICH_SU';
// Datagov
/* ------------------------------------------------------------------------- */
export const GET_DANHSACH_STORE = 'GET_DANHSACH_STORE';
export const GET_DATA_COUNT_STANDARDS = 'GET_DATA_COUNT_STANDARDS';
export const GET_DATA_COUNT_RULES = 'GET_DATA_COUNT_RULES';
export const GET_DATA_COUNT_STORE = 'GET_DATA_COUNT_STORE';
export const GET_DATA_COUNT_TABLE = 'GET_DATA_COUNT_TABLE';
export const GET_DATA_COUNT_COLUMN = 'GET_DATA_COUNT_COLUMN';
export const GET_DATA_COUNT_NDS = 'GET_DATA_COUNT_NDS';
export const GET_LAST_UPDATE = 'GET_LAST_UPDATE';
