/* -------- Common actions -------- */
import {
  API_FAILED,
  API_FETCH,
  GET_DANHSACH_STORE,
  GET_DATA_DAC_QUYEN,
  GET_DATA_DS_GOIHETHONG,
  GET_DATA_DS_QUYEN,
  GET_DATA_QUAN_HUYEN,
  SET_LOADING_APP,
  SET_SHOULD_LOAD_INITIAL_COMBOBOXS
} from 'constants/actionTypes';
import { generateKeyAPI } from 'helpers';
import axios from 'axios';
import { API_AUTH_DOMAIN } from 'constants/api';

const { CancelToken } = axios;
const canceledList = [];

export const cancelCommonAPI = () => () => {
  if (canceledList.length > 0) {
    canceledList.map(item => item.canceled.cancel(`cancel_API-${item.key}`));
  }
};

/**
 * Set loading cho App
 * @param {Boolean} loading Giá trị loading
 * @returns {Object} result
 */
export const setLoadingApp = (loading = false) => ({
  type: SET_LOADING_APP,
  loading
});

/**
 * Set điều kiện load comboboxs khởi tạo - "shouldLoadInitialComboboxs" trong reducers.common
 * @param {Boolean} value Giá trị của "shouldLoadInitialComboboxs"
 * @returns {Object} result
 */
export const setShouldLoadInitialComboboxs = (value = false) => ({
  type: SET_SHOULD_LOAD_INITIAL_COMBOBOXS,
  value
});

// Danh sách quận huyện thuộc TP.Đà Nẵng
export const getDanhSachQuanHuyen = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  // Cấp quận huyện
  const level = 'DISTRICT';
  // Thành phố Đà Nẵng
  const parentCode = 48;
  return dispatch({
    types: [API_FETCH, GET_DATA_QUAN_HUYEN, API_FAILED],
    payload: {
      request: {
        baseURL: API_AUTH_DOMAIN,
        url: '/api/administrative-division',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params,
          level,
          parentCode
        }
      }
    }
  });
};

// Danh sách Gói Hệ Thống
export const getAllDanhSachGoiHeThong = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DS_GOIHETHONG, API_FAILED],
    payload: {
      request: {
        url: '/api/system-package',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params,
          size: 999
        }
      }
    }
  });
};

// Danh sách Gói Hệ Thống
export const getDanhSachQuyen = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DS_QUYEN, API_FAILED],
    payload: {
      request: {
        url: '/api/quyen',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Danh sách Gói Hệ Thống
export const getDacQuyen = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DATA_DAC_QUYEN, API_FAILED],
    payload: {
      request: {
        url: '/api/dac-quyen',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};

// Get list action - vi phạm chuẩn (api datagov)
export const getDanhSachStore = params => (dispatch, getState) => {
  canceledList.push({
    key: generateKeyAPI(),
    canceled: CancelToken.source()
  });
  const cancelToken = canceledList[canceledList.length - 1].canceled.token;
  return dispatch({
    types: [API_FETCH, GET_DANHSACH_STORE, API_FAILED],
    payload: {
      request: {
        url: '/api/ds-stores',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.type} ${getState().auth.token.accessToken}`
        },
        cancelToken,
        params: {
          ...params
        }
      }
    }
  });
};
