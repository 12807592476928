import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ButtonIcon,
  Container,
  ContentWrapper,
  Select
} from 'components/common';
import {
  DEFAULT_VALUE
} from 'constants/variables';
import {
  Col,
  Form,
  Row,
  Transfer
} from 'antd';
import {
  CloseOutlined,
  SaveOutlined
} from '@ant-design/icons';
import {
  colLayout,
  formDefaultProps,
  formItemDefautProps,
  rowLayout
} from 'constants/form';
import {
  alertError,
  alertSuccess,
  convertResponseAPI,
  execFunction,
  hasErrorResponseAPI,
  throwError
} from 'helpers';
import messages from 'constants/messages';
import {
  get,
  isEmpty
} from 'lodash';
import {
  cancelBaoMatDuLieuAPI,
  getAllDanhSachChucNang,
  getAllDanhSachGoiHeThong,
  getAllDanhSachQuyen,
  getChiTietChucNang,
  getDanhSachDacQuyen,
  getDanhSachTaiKhoan,
  getDataChucDanhVaiTroVaQuyen,
  putPhanBoQuyenVaoChucNang
} from 'actions';

const FormItem = Form.Item;

class PhanBoQuyenVaoChucNang extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      size: 'default',
      parentId: undefined,
      loadingButton: false,
      disabledButton: false,
      dsQuyenID: {},
      nameInputForm: {
        chucNangChon: 'chucNangChon'
      },
      dataForm: {},
      dataValue: {},
      chucNangDaChon: []
    };
    // Kiểm tra component có đang được "mounted"
    this.mounted = true;
    // Khởi tạo ref cho form
    this.form = React.createRef();
  }

  async componentDidMount() {
    // Get danh sách tài khoản
    // await this.getAllDanhSachChucNang();
    // await this.props.actions.getDanhSachTaiKhoan();
    // await this.props.actions.getDanhSachDacQuyen();
    await this.onGetData();
  }

  componentWillUnmount() {
    // Set mounted
    this.setMounted(false);
    // Cancel API
    this.props.actions.cancelBaoMatDuLieuAPI();
  }

  /**
   * Set mounted
   * @param {Boolean} value
   * @returns {Boolean}
   * @memberof PhanBoQuyenVaoChucNang
  */
  setMounted = (value = true) => {
    this.mounted = value;
    return this.mounted;
  }

  /**
   * Get mounted
   * @returns {Boolean}
   * @memberof PhanBoQuyenVaoChucNang
   */
  getMounted = () => this.mounted

  /**
   * Set state properties
   * @param {Object} data the data input
   * @param {Function} callback the function which will be called after setState
   * @returns {void} call this.setState to update state
   * @memberof PhanBoQuyenVaoChucNang
   */
  setStateData = (state, callback) => {
    if (!this.getMounted()) {
      return;
    }
    this.setState(state, callback);
  }

  /**
   * onGetData: get dữ liệu về chức danh, vai trò và quyền
   * @returns {void} set data payload
   * @memberof PhanBoQuyenVaoChucNang
   */
  onGetData = async () => {
    const {
      nameInputForm
    } = this.state;
    let isError = false;
    try {
      this.setStateData({ loading: true });
      const params = {
        id: get(this.state.dataForm, 'id'),
        size: 100
      };
      const response = await this.props.actions.getAllDanhSachChucNang(params);
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error', messages.ERROR_SYSTEM));
      }
      
      const dsQuyen = await this.props.actions.getAllDanhSachQuyen({ size: DEFAULT_VALUE.MAX_SIZE });
      const getQuyen = dsQuyen && dsQuyen.payload ? dsQuyen.payload : {};
      if (hasErrorResponseAPI(dsQuyen)) {
        return throwError(get(dsQuyen, 'error', messages.ERROR_SYSTEM));
      }

      const data = response.payload;
      this.setStateData({
        [nameInputForm.chucNangChon]: data.chucDanhs.filter(item => item.daChon).map(item => item.id),
        dsQuyenID: getQuyen
      });
    } catch (error) {
      isError = true;
    } finally {
      this.setStateData({ isError, loading: false });
    }
    return true;
  }

  /**
   * Reload Phân bổ chức năng vào gói
   * @returns {void} window.location.reload()
   * @memberof PhanBoChucNangVaoGoiHeThong
   */
  reloadPage = async () => {
    window.location.reload();
  }

  /**
   * handleChangeTransfer: Change dữ liệu trong danh sách transfer
   * @returns {void} set data payload
   * @memberof PhanQuyenTaiKhoan
   */
  handleChangeTransfer = (name, value) => {
    const {
      dataValue,
      dsQuyenID,
      nameInputForm
    } = this.state;
    const chucNangChonArr = [];
    const quyenArr = [];
    if (name === nameInputForm.chucNangChon) {
      if (dsQuyenID && dsQuyenID.content.length !== 0 && value.length !== 0) {
        dsQuyenID.content.forEach(item => (
          value.forEach((element) => {
            if (element === item.id) {
              chucNangChonArr.push(item.id);
            }
          })
        ));
      }
    }

    const quyenChonKhongTrung = quyenArr.filter(
      (value, index, arr) => quyenArr.indexOf(value) === index
    );
    
    // Sắp xếp lại danh sách quyền đã chọn
    quyenChonKhongTrung.sort((a, b) => a - b);
    
    const quyenChonArr = [];
    // Nếu chưa có quyền được chọn trừ trước
    if (!get(dataValue, 'quyenChonModel') && !isEmpty(quyenChonKhongTrung) && !isEmpty(get(dataValue, 'quyen'))) {
      quyenChonKhongTrung.forEach(element => (
        dataValue.quyen.forEach((item) => {
          if (element === item.id) {
            quyenChonArr.push(item);
          }
        })
      ));
    }

    // Nếu đã có quyền được chọn
    if (get(dataValue, 'quyenChonModel') && !isEmpty(quyenChonKhongTrung)) {
      quyenChonKhongTrung.forEach(element => (
        dataValue.dacQuyenChoTatCaQuyenModel.forEach((item) => {
          if (element === item.id) {
            quyenChonArr.push(item);
          }
        })
      ));
    }

    this.setStateData({
      chucNangDaChon: chucNangChonArr,
      dataValue: {
        ...this.state.dataValue,
        [name]: value,
        [nameInputForm.chucNangChon]: chucNangChonArr,
        quyenChon: quyenChonKhongTrung
      }
    });
  }

  /**
   * Reset form
   * @returns {void} Reset fields trong form
   * @memberof LayLaiMatKhauForm
   */
  resetForm = async () => {
    await this.setStateData({
      isSubmitting: false,
      dsQuyenID: {}
    });
    const resetFields = get(this.form.current, 'resetFields');
    await execFunction(resetFields);
  }

  /**
   * Xử lý cho nút hủy
   * @returns {void} Gọi lại function onGetData
   * @memberof PhanBoQuyenVaoChucNang
   */
  handleCancelForm = () => {
    this.onGetData();
  }

  /**
   * Parse data example trước khi request lên API
   * @param {Object} data Data form hiện tại
   * @returns {Object} Data sẽ được request lên API
   * @memberof Example01Form
   */
  parseDataRequest = (data) => {
    const parseData = {
      id: data.id ? data.id : null,
      listQuyen: data.listQuyen ? data.listQuyen : null
    };
    return parseData;
  }

  /**
   * Xử lý cho nút lưu
   * @returns {void} Gửi dữ liệu trả về thành công hoặc thất bại
   * @memberof PhanQuyenTaiKhoan
   */
  handleSubmitLuuDuLieu = async () => {
    try {
      this.setStateData({
        disabledButton: true,
        loadingButton: true
      });
      const {
        chucNangDaChon,
        parentId
      } = this.state;
      const params = {
        id: parentId || [],
        listQuyen: chucNangDaChon || []
      };
      // Gọi api truyền params
      const dataRequest = this.parseDataRequest(params);
      const request = this.props.actions.putPhanBoQuyenVaoChucNang;
      const firstParam = params.id;
      const secondParam = dataRequest;
      const response = await request(firstParam, secondParam);
      
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error', messages.ERROR_SYSTEM));
      }
      alertSuccess(messages.SAVE_SUCCEED);
    } catch (error) {
      alertError(messages.ERROR_SYSTEM);
    } finally {
      this.setStateData({
        disabledButton: false,
        loadingButton: false
      });
    }

    return true;
  }

  renderItem = (item) => {
    const customLabel = (
      <span className="custom-item">
        {item.code} - {item.name}
      </span>
    );
    return {
      label: customLabel,
      value: get(item, 'id')
    };
  };

  /**
   * Get data danh sách Gói Hệ Thống
   * @returns {void} Cập nhật state.dataExample
   * @memberof ThemMoiGoiHeThong
   */
  getAllDanhSachChucNang = async () => {
    let isError = false;
    try {
      await this.setStateData({ loading: true });
      const response = await this.props.actions.getAllDanhSachChucNang({ size: DEFAULT_VALUE.MAX_SIZE });
      
      if (hasErrorResponseAPI(response)) {
        return throwError(get(response, 'error', messages.ERROR_SYSTEM));
      }
      const dsChucNangs = convertResponseAPI(response);
      await this.setStateData({ dsChucNang: dsChucNangs });
    } catch (error) {
      isError = true;
    } finally {
      this.setStateData({ isError, loading: false });
    }
    return true;
  }

  /**
   * onChangeSelect: Set state data select box
   * @param {Object} name name of select
   * @param {Function} value value of select
   * @returns {void} set state select
   * @memberof PhanQuyenTaiKhoan
   */
  onChangeSelect = async (name, value) => {
    const data = await this.props.actions.getAllDanhSachQuyen({ size: DEFAULT_VALUE.MAX_SIZE });
    
    const listChucNangDaChon = await this.props.actions.getChiTietChucNang(value);
    const getListChucNang = listChucNangDaChon && listChucNangDaChon.payload && listChucNangDaChon.payload.quyen;
    const getQuyen = data && data.payload ? data.payload : {};
    const getDSChucNangdDaChon = [];
    
    getListChucNang.forEach((item) => {
      getDSChucNangdDaChon.push(item.id);
    });
    this.setStateData({
      parentId: value,
      dsQuyenID: getQuyen,
      chucNangDaChon: getDSChucNangdDaChon
    });
  }

  render() {
    const {
      disabledButton,
      loadingButton,
      nameInputForm,
      size,
      dataForm,
      chucNangDaChon,
      dsQuyenID
    } = this.state;

    return (
      <Container
        containerClass="trangchu-page"
        ellipsis={false}
        title="PHÂN BỔ QUYỀN VÀO CHỨC NĂNG"
      >
        <Form
          {...formDefaultProps.vertical}
          ref={this.form}
          className="form-container"
          name="PhanBoQuyenVaoChucNang"
          scrollToFirstError
        >
          <ContentWrapper
            className="mb20"
          >
            <Row {...rowLayout}>
              <Col {...colLayout.half}>
                <FormItem
                  {...formItemDefautProps.default}
                  initialValue={get(dataForm, 'id') || undefined}
                  label="Chức Năng"
                  name="id"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select
                    dataSelect={get(this.props.dsChucNang, 'content', [])}
                    fieldsName={{
                      value: 'id',
                      name: 'name'
                    }}
                    // eslint-disable-next-line no-undef
                    onChange={value => this.onChangeSelect('id', value)}
                    placeholder="- Chọn Chức Năng -"
                    // eslint-disable-next-line no-undef
                    size={size}
                    style={{ width: '98.5%' }}
                  />
                </FormItem>
              </Col>
            </Row>
          </ContentWrapper>
          <ContentWrapper
            footer={(
              <Row {...rowLayout}>
                <Col {...colLayout.full}>
                  <div className="btn-submit-group">
                    <ButtonIcon
                      className="m5 btn-cancel"
                      disabled={false}
                      IconInstant={CloseOutlined}
                      onClick={this.reloadPage}
                      text="Hủy"
                    />
                    <ButtonIcon
                      className="m5 btn-submit green"
                      disabled={disabledButton}
                      IconInstant={SaveOutlined}
                      loading={loadingButton}
                      onClick={this.handleSubmitLuuDuLieu}
                      text="Lưu"
                    />
                  </div>
                </Col>
              </Row>
            )}
          >
            <Row {...rowLayout}>
              <Col {...colLayout.full}>
                <h2 className="mb5">Danh sách Quyền</h2>
                <Transfer
                  className="mb10"
                  dataSource={!isEmpty(dsQuyenID) ? get(dsQuyenID, 'content') : []}
                  listStyle={{
                    width: 'calc(50% - 20px)',
                    height: 300,
                    minWidth: 300,
                    marginBottom: 10
                  }}
                  onChange={value => this.handleChangeTransfer(nameInputForm.chucNangChon, value)}
                  render={this.renderItem}
                  rowKey={record => record.id}
                  targetKeys={chucNangDaChon || []}
                  titles={
                    [
                      'Danh sách Quyền',
                      'Đã chọn'
                    ]
                  }
                />
              </Col>
            </Row>
          </ContentWrapper>
        </Form>
      </Container>
    );
  }
}

PhanBoQuyenVaoChucNang.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  dsTaiKhoan: PropTypes.objectOf(PropTypes.any).isRequired,
  dsChucNang: PropTypes.objectOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

PhanBoQuyenVaoChucNang.defaultProps = {
  dsChucNang: {}
};

const mapStateToProps = state => ({
  dsTaiKhoan: state.baoMatDuLieu.dsTaiKhoan,
  dsChucNang: state.common.dsChucNang,
  dsQuyenID: state.baoMatDuLieu.dsQuyenID
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    cancelBaoMatDuLieuAPI,
    getDanhSachTaiKhoan,
    putPhanBoQuyenVaoChucNang,
    getAllDanhSachChucNang,
    getDanhSachDacQuyen,
    getChiTietChucNang,
    getAllDanhSachQuyen,
    getDataChucDanhVaiTroVaQuyen,
    getAllDanhSachGoiHeThong
  }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhanBoQuyenVaoChucNang);
