import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import common from './common';
import example from './example';
import baoMatDuLieu from './baoMatDuLieu';
import quanLyChucDanh from './quanLyChucDanh';
import quyen from './quyen';
import quanLyNguoiDung from './quanLyNguoiDung';
import quanLyVaiTro from './quanLyVaiTro';
import thongKe from './thongKe';

const rootReducer = history => combineReducers({
  auth,
  common,
  example,
  baoMatDuLieu,
  quanLyChucDanh,
  quyen,
  quanLyNguoiDung,
  quanLyVaiTro,
  thongKe,
  router: connectRouter(history)
});

export default rootReducer;
